<template>
  <v-card class="header-action__card dialogCompany-box">
    <div class="dialog-company">
      <div class="content-company-main">
        <div class="content-company-box">
          <div class="content-company">
            <div class="content-company-header">
              <span
                >いつもTIME SHARINGをご利用いただきありがとうございます。</span
              ><span
                >TIME
                SHARINGの運営会社「株式会社あどばる」は、ビジョングループの一員です。</span
              >
            </div>
            <div class="content-company-info left">
              <div class="content-company-left_header">株式会社あどばる</div>
              <div
                class="content-company-left_content"
                v-for="(item, index) in companyAdval"
                :key="index"
              >
                <div class="content-company-left_content_title">
                  {{ item.content }}
                </div>
                <div class="content-company-left_content_text">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div class="content-company-info right">
              <div class="content-company-left_header">株式会社ビジョン</div>
              <div
                class="content-company-left_content"
                v-for="(item, index) in companyVision"
                :key="index"
              >
                <div class="content-company-left_content_title">
                  {{ item.content }}
                </div>
                <div class="content-company-left_content_text">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="blue-grey"
      class="header-action__button header-action__dialogCompany"
      fab
      @click="handleCloseModal"
    >
      <v-icon class="header-action__icon" dark color="black">
        mdi-window-close
      </v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch } from "vue";
const props = defineProps({
  isShowdialogCompany: {
    type: Boolean,
  },
});
const isOpen = ref(props.isShowdialogCompany);
const emit = defineEmits(["closeDialogCompany"]);
watch(props, (value) => (isOpen.value = value.isShowdialogCompany));
const handleCloseModal = () => {
  isOpen.value = false;
  emit("closeDialogCompany");
};

const companyAdval = [
  {
    content: "創業",
    text: "2008年7月",
  },
  {
    content: "設立",
    text: "2016年6月",
  },
  {
    content: "代表取締役",
    text: "中野邦人",
  },
  {
    content: "資本金",
    text: "10,000,000円",
  },
  {
    content: "資本準備金",
    text: "474,160,000円",
  },
  {
    content: "従業員数",
    text: `67名（社員 32名 / アルバイト35名）
※2022年3月末現在`,
  },
  {
    content: "事業内容",
    text: "スペースシェアリング事業",
  },
];
const companyVision = [
  {
    content: "創業",
    text: "1995年6月1日",
  },
  {
    content: "設立",
    text: "2001年12月4日",
  },
  {
    content: "代表取締役会長 CEO",
    text: "佐野 健一",
  },
  {
    content: "資本金",
    text: "2,571,600,000円（2023年12月31日）",
  },
  {
    content: "従業員数",
    text: `国内: 803名 (社員 660名 アルバイト 143名)
海外: 93名 (社員 84名 アルバイト 9名)
※2023年12月末現在`,
  },
  {
    content: "事業内容",
    text: `1.グローバルWiFi事業
  海外事業
  国内事業
2.情報通信サービス事業
  固定通信事業
  移動体通信事業
  ブロードバンド事業
  OA機器販売事業
  インターネットメディア事業
3.グランピング・ツーリズム事業
4.その他`,
  },
  {
    content: "上場証券取引所",
    text: `東京証券取引所プライム市場
（証券コード：9416）`,
  },
];
</script>
<style lang="scss" scoped>
@import "./CompanyDialogCard.scss";
$XS: 576px;
$SM: 768px;
$MD: 1200px;
.header-action__dialogCompany {
  top: 0;
  right: 0;
}
.dialogCompany-box {
  border-top: 0;
  border-bottom: 0;
}
.content-company-box {
  background: linear-gradient(-45deg, #00aeb6 1%, #009bdb 50%, #7db9e8 100%);
  padding: 5px;
}
.content-company-left {
  margin: 0 0 0 2px;
  width: calc(50% - 10px);
}
.content-company-left_content {
  margin-bottom: 5px;
}
.content-company-header {
  grid-area: myArea;
}
.content-company-header {
  display: flex;
  flex-direction: column;
}
.content-company-main {
  padding: 20px;
}
.content-company {
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  &-header {
    width: calc(100% - 0px);
    font-size: 26px;
    padding: 15px 30px;
    margin: 0 0 30px;
    font-weight: normal;
    background: linear-gradient(-45deg, #00aeb6 1%, #009bdb 50%, #7db9e8 100%);
    border-radius: 8px;
    text-align: center;
    line-height: 1.75em;
    height: fit-content;
    span {
      font-size: 26px;
      color: #fff;
      white-space: pre-wrap;
    }
  }
  &-info {
    width: calc(50% - 60px);
    &_header {
      width: 100%;
      font-weight: bold;
      font-size: 35px;
      margin: 0 0 18px;
    }
    &_content {
      gap: 10px;
      display: flex;
      width: calc(100%);
      margin-bottom: 10px;
      &_title {
        width: 40%;
        padding: 10px;
        font-size: 16px;
        color: #212121;
      }
      &_text {
        padding: 6px;
        font-size: 16px;
        color: #212121;
        white-space: pre-line;
      }
    }
  }
  &-info.left {
    margin: 0 0 0 50px;
  }
  &-info.right {
    margin: 0 50px 0 0;
  }
}
@media screen and (max-width: $MD) {
  .v-overlay__content {
    height: auto !important;
    max-width: 92% !important;
  }
}
@media screen and (max-width: $SM) {
  .v-dialog .v-overlay__content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .content-company-left {
    margin: 0 0 0 2px;
    width: calc(50% - 10px);
  }
  .content-company-left_content {
    margin-bottom: 5px;
  }
  .content-company-main {
    padding: 7px;
  }
  .content-company {
    padding: 7px;
    display: block;
  }
  .content-company-header {
    padding: 5px 5px;
    margin: 0;

    line-height: unset;
  }
  .content-company-header span {
    font-size: 15px;
  }
  .content-company-info {
    width: 90%;
  }
  .content-company-info.left,
  .content-company-info.right {
    margin: auto;
  }
  .content-company-left_header {
    font-size: 18px;
    margin-top: 10px;
    color: rgb(98, 98, 98);
  }
  .content-company-left {
    margin: 0 0 0 2px;
    width: 100%;
    color: rgb(98, 98, 98);
  }
  .content-company-left_header {
    margin: 16px 0 0px 0px;
    color: rgb(98, 98, 98);
  }
  .content-company-left_content_title {
    width: 40%;
    padding: 0;
    font-size: 14px;
    margin: 0;
  }
  .content-company-left_content_text {
    padding: 0;
    width: 60%;
    font-size: 14px;
  }
  .content-company-left_content {
    margin-top: 6px;
  }
  .header-action__button {
    border: 1px solid black;
    min-width: 49px;
    height: 40px;
    position: absolute;
    background: black;
    opacity: 0.5;
    border-radius: 0;
    padding: 0;
  }
}
</style>
