/* eslint-disable */
import Corgiria1 from "@/client/assets/images/PartySceneNew/corgiria-1.png";
import Corgiria2 from "@/client/assets/images/PartySceneNew/corgiria-2.png";
import Corgiria3 from "@/client/assets/images/PartySceneNew/corgiria-3.png";
import Corgiria4 from "@/client/assets/images/PartySceneNew/corgiria-4.png";

import Img1 from "@/client/assets/images/PartyScene002/001.png";
import Img2 from "@/client/assets/images/PartyScene002/002.png";
import Img3 from "@/client/assets/images/PartyScene002/003.png";
import Img4 from "@/client/assets/images/PartyScene002/004.png";
import Img5 from "@/client/assets/images/PartyScene002/005.png";
import Img6 from "@/client/assets/images/PartyScene002/006.png";
import Img7 from "@/client/assets/images/PartyScene002/007.png";
import Img8 from "@/client/assets/images/PartyScene002/008.png";
import Img9 from "@/client/assets/images/PartyScene002/009.png";
import Img10 from "@/client/assets/images/PartyScene002/010.png";
import Img11 from "@/client/assets/images/PartyScene002/011.png";
import Img12 from "@/client/assets/images/PartyScene002/012.png";
import Img13 from "@/client/assets/images/PartyScene002/013.png";
import Img14 from "@/client/assets/images/PartyScene002/014.png";
import Img15 from "@/client/assets/images/PartyScene002/015.png";
import Img16 from "@/client/assets/images/PartyScene002/016.png";
import Img17 from "@/client/assets/images/PartyScene002/017.png";
import Img18 from "@/client/assets/images/PartyScene002/018.png";
import Img19 from "@/client/assets/images/PartyScene002/019.png";

export const SCENE002_DATA = {
  page_name: "歓送迎会、懇親会、同窓会",
  party_banner: {
    imgs: [
      {
        img_pc: Img1,
        img_mobile: Img1,
      },
    ],
    text_bold: `気の合う仲間とパーティーするなら<br />
    TIME SHARINGがおすすめ！`,
    text_normal: `歓送迎会・懇親会・同窓会・女子会・ママ会・誕生日会・推し活など<br />
                    気の合う仲間とパーティーをするなら<br class="for-sp"/>TIME SHARINGにお任せください。`,
  },
  party_services: [
    {
      img: Img2,
      title: `好立地・アクセスの良さ`,
      content: `気の合う仲間とのパーティー会場は集まりやすい場所とアクセスの良さで選ぶのがおすすめ！<br />
            タイムシェアリングでは、都内の好立地会場多数あります！`,
    },
    {
      img: Img3,
      title: `大人数でのパーティーに◎`,
      content: `タイムシェアリングは、最低利用料金に縛りはありますが「時間貸し」のスペース提供となるため、飲食店のように大人や子供の人数で料金が変わるわけではありません。大人数のパーティーになるほど、お得に利用できます！`,
    },
    {
      img: Img4,
      title: `レイアウトの変更可能`,
      content: `タイムシェアリングなら、レイアウトの変更も可能となるので、立食形式、着席などパーティーの内容に合わせたレイアウトでご利用いただくことができます。`,
    },
    {
      img: Img5,
      title: `サプライズもOK`,
      content: `会場によっても異なりますが、控え室や裏導線がある会場もあるためサプライズを企画することも可能です。料理などのプランもカスタマイズ可能なのでご相談ください。`,
    },
  ],

  party_query: {
    img: Img6,
    title: `歓送迎会・同窓会・女子会・誕生日会、推し活などのパーティー会場の予約の際によくあるお困りごと`,
    list: [
      {
        desc: `20〜50人規模で<br />
                貸し切りパーティーを<br />
                開催したいが<br />
                人数規模的に貸し切りできる<br />
                会場が少なく探すのに困る`,
      },
      {
        desc: `飲食店などで<br />
                開催することが多いが、<br />
                完全個室や貸し切りが<br />
                できない場合<br />
                周りに気をつかう`,
      },
      {
        desc: `料理がメインとなる飲食店で<br />
                開催してしまうと、<br />
                子供料金も含めて取られるため<br />
                思ったより予算が高くなる`,
      },
    ],
  },
  party_solution: {
    desc: `TIME SHARING（タイムシェアリング）なら、歓送迎会・同窓会・女子会・誕生日会、推し活などに適した施設をご案内できます！歓送迎会や同窓会、女子会、ママ会などのパーティーでは、規模も様々。開催場所や曜日によっては、店舗など貸切りが難しいということも想定されます。<br />
          例えば、表参道エリアで10〜20名でパーティーを開催するとなると、20名以下などの規模だとレストランなどの店舗は貸切ることができないといったケースもあります。<br />
          また、会場は見つかっても人気の曜日・時間に予約が集中するので、希望日時が埋まっていることも…<br />
          ご希望の日時や内容で会場を探すのはとても大変です。<br />
          このような悩みがあれば一度TIME SHARING（タイムシェアリング）のコンシェルジュにご相談ください！パーティーの内容を踏まえて最適な会場をご提案いたします。`,
    img_right_sp: Img9,
    img_right: Img9,
    img_left1: Img7,
    img_left2: Img8,
    img_left1_sp: Img7,
    img_left2_sp: Img8,
  },
  party_venue: {
    title: `歓送迎会・同窓会・女子会などで<br class="sp-block"/>「失敗しないための会場選び」<br class="sp-block"/>のポイント`,
    list: [
      {
        point_label: `Point.01`,
        title: `開催する会の雰囲気に合わせた会場を探す`,
        desc: `歓迎会・送迎会や同窓会、女子会・ママ会、推し活パーティーなど、開催する会の雰囲気に合わせて会場を探すのがポイントです。<br />
                会場によって、パーティーの雰囲気もガラッと変わるため、どのような会なのかを踏まえ会場を探すことや、小さなお子様やご年配の方が参加する会など参加者の年齢層などによって会場を探すこともおすすめしています。`,
      },
      {
        point_label: `Point.02`,
        title: `開催場所と人数を決める`,
        desc: `歓送迎会・同窓会、女子会・ママ会、推し活などのパーティーを開催する場合は、会に参加する人が集まりやすい会場選びもポイントです。<br />
              また、規模（参加人数）が大きくなればなるほど会場が限られてくるため、現実的な参加人数だけでなく最大参加人数を踏まえて会場探しをしておくと良いでしょう。`,
      },
      {
        point_label: `Point.03`,
        title: `予算を決め会場を選ぶ`,
        desc: `最後に予算を決め、パーティー会場を選びましょう。<br />
        食事内容や飲み物（アルコールありなしなど）などの飲食を用意するか、持ち込むか（持ち込める会場か）などを確認の上、パーティーの予算を決めましょう<br />
        1人当たりの予算に割り振って、希望のパーティーが開催できる会場を選びます。`,
      },
    ],
    carousel_img: [Img10, Img11, Img12],
  },
  party_facilities: {
    title: `歓送迎会や同窓会、<br class="sp-block"/>女子会・ママ会、推し活などのパーティー<br />
    開催時におすすめの設備・オプション`,
    list: [
      {
        img: Img13,
        title: `プロジェクター`,
        desc: `歓送迎会や同窓会、想い出の写真や映像をプロジェクターで投影するといったニーズが多くあります。また、共通の趣味など映像などを投影をするならプロジェクターは必須です。`,
      },
      {
        img: Img14,
        title: `ワイヤレスマイク`,
        desc: `周年記念パーティーには、ワイヤレスマイクがあると会の進行がよりスムーズです。マイクを回してコメントをもらう際にもワイヤレスマイクが便利です。`,

      },
      {
        img: Img15,
        title: `キッチン`,
        desc: `パーティー会場の中には、キッチン使える会場もあります。気の合う仲間で料理を作って楽しむこともできます。`,
      },
      {
        img: Img16,
        title: `ケータリングプラン`,
        desc: `ケータリングプランをご用意しておりますが、パーティーの規模や内容によって食事やドリンクをカスタマイズすることも可能となります。お電話にてご相談ください。`,
      },
    ],
  },
  party_recommend: {
    title: `歓送迎会・懇親会・同窓会・女子会・誕生日会・推し活などのパーティー<br />
    開催時におすすめ4会場`,
    list_ids: [69, 4, 20, 28],
    // ① Glade Park Shinjuku　https://time-sharing.jp/detail/10126
    // ② EBISU SHOW ROOM　https://time-sharing.jp/detail/10041
    // ③ Mace Minami Aoyama　https://time-sharing.jp/detail/10009
    // ④ ounge-R TERRACE Shibuya　https://time-sharing.jp/detail/10007
    button_label: `おすすめのパーティー<br class="sp-block" />会場をもっと見る`,
    title_portion: `歓送迎会・懇親会・同窓会・女子会・誕生日会・推し活など、<br />
        パーティー<br class="sp-block" />利用のお客様の声`,
    list: [
      {
        img: Img17,
        desc: `歓迎会の開催が決まり、いつものレストラン開催ではなくパーティースペースでイベントを企画して開催をした際に利用しました。<br />
          100人規模で会場を貸し切っての開催となったため会場探しに苦戦しましたが、コンシェルジュに協力いただきとても良い会場が見つかりました！`,
      },
      {
        img: Img18,
        desc: `ママ会で利用しました。子供を含めると50名を超える大人数でレストランでは収拾がつかなくなるためパーティースペースを利用。<br />
        自分たちで備え付けのキッチンを借りて料理を作り、とても楽しい時間を過ごすことができました。時間も長めに抑えられ、会の準備〜撤収まで余裕を持って利用できたのがよかったです。`,
      },
      {
        img: Img19,
        desc: `推し活仲間と集まる会でパーティー施設を利用しました。推しの出演しているライブや動画/TV番組などの映像を大型のスクリーンに映し出し、みんなで一緒に楽しみました。<br />
        その後も定期的に同じ会場で推し活会を開催しています。`,
      },
    ],
  },
  party_concierge: {
    list: [
      {
        img: Corgiria1,
        title: `お電話でのご相談が可能`,
        desc: `スペースのご予約はお電話でもお受けしております。より詳細な内容をお伝えしたり、ご希望のエリアや内容に沿ったスペース探しをコンシェルジュがサポートいたします。お気軽にお問い合わせください。`,
      },
      {
        img: Corgiria2,
        title: `人数に応じたスペースをご提案`,
        desc: `1名でご利用のテレワークブース、10〜30名でご利用可能なセミナー開催できる会議室、100名以上着席可能な大規模貸し会議室、200名でご利用可能なパーティースペースまで、人数に応じた様々なスペースをご案内できます。レイアウト変更のできるスペースも多数ご用意しておりますので、ご利用用途に合わせてご提案可能です。`,
      },
      {
        img: Corgiria3,
        title: `無料内見サービスで納得がいく会場選びのお手伝い`,
        desc: `セミナーやパーティー会場など、ご予約される前に、無料内見サービスでスペースの確認が可能です。レイアウトやスペースの雰囲気を事前に確認してご予約いただけるので納得のいく会場が選べます。お気軽にお電話にてご相談ください。※一部対応不可のスペースがあります。ご了承ください。`,
      },
      {
        img: Corgiria4,
        title: `お食事内容、料金プランなどカスタマイズ可能`,
        desc: `周年記念パーティーや結婚式二次会、歓送迎会などパーティー内容や人数に合わせてお食事内容、料金プランなどカスタマイズ可能です。<br />
              カスタマイズはお電話にてコンシェルジュにご相談ください。`,
      },
    ],
  },
  party_faq: {
    list: [
      {
        question: `レイアウトの変更は可能ですか？`,
        answer: `原則としてお客様ご自身でのレイアウト変更をお願いしております。<br />
          レイアウト変更をされた場合、必ず退室前に原状復帰をお願いいたします。<br />
          弊社に事前変更を依頼する場合は、別途5万円(税別)の追加料金が発生いたします。`,
      },
      {
        question: `何分前から入室可能ですか？`,
        answer: `予約時間ちょうどからご入室可能です。<br />
          準備～撤収を含めた時間でご予約をお願いいたします。<br />
          ご予約時間より前の入室や、時間を過ぎての退室をされる場合は、必ず延長予約をお取りください。`,
      },
      {
        question: `キッチン利用は可能ですか？また、飲食物の持込みはできますか？`,
        answer: `キッチンの利用や、飲食物の持ち込みができる会場があります。パーティー会場によって、有料オプションの会場や無料で使える会場もあるため、会場の詳細ページにてご確認の上ご予約ください。`,
      },
      {
        question: `調味料はありますか？`,
        answer: `衛生面に配慮し、調味料を含む食品のご用意はございません。`,
      },
      {
        question: `音響設備はありますか？`,
        answer: `スペースによって音響設備の内容は異なりますがご用意しております。<br />
          無料備品としてミキサーのご用意をしているスペースもあり、ミキサーにご持参いただいたPCやスマートフォンを接続し、音を流すことが可能なスペースもあります。<br />
          接続方法や備品の詳細についても、スペースごとに音響設備の内容が異なりますので、各スペースの詳細ページをご確認ください。`,
      },
      {
        question: `撮影利用は可能ですか？`,
        answer: `撮影での利用も可能です。ただし、撮影の内容によってはご利用いただけない場合がございます。撮影内容を記載の上お問い合わせください。<br />
          ※撮影のお客様には必ず利用前の内容確認を行わせていただきます。内容によりお受けできない場合がございますので予めご了承ください。<br />
          ※お申込み後、弊社からサイト内メッセージやメール、お電話にて内容確認のご連絡いたしますので、必ずご確認ください。`,
      },
    ],
  },
  navItem: [
    {
      title: "トップに戻る",
      href: "#",
    },
    {
      title:
        "歓送迎会・同窓会・女子会などで<br class='pc-block'/> 「失敗しない<br class='sp-block'/>ための会場選び」のポイント",
      href: "#main-section",
    },
    {
      title: "歓送迎会や同窓会、女子会・ママ会、<br/>推し活などのパーティー<br/>開催時におすすめの設備・オプション",
      href: "#facilities",
    },
    {
      title: "歓送迎会・懇親会・同窓会・女子会<br/>・誕生日会・推し活などのパーティー<br />開催時におすすめ4会場",
      href: "#recommend",
    },
    {
      title: "歓送迎会・懇親会・同窓会・女子会<br/>・誕生日会・推し活など、<br/>パーティー利用のお客様の声",
      href: "#review",
    },
    {
      title: "お申込方法",
      href: "#apply",
    },
    {
      title: "TIME SHARINGの<br class='sp-block'/>コンシェルジュサポート",
      href: "#concierge",
    },
    {
      title: "ビジネスアカウント6つのメリット",
      href: "#business",
    },
    {
      title: "よくあるご質問",
      href: "#faq",
    },
    {
      title: "タイムシェアリング for Party にもどる",
      href:
        window.location.protocol + "//" + window.location.hostname + "/forparty",
    },
    {
      title: "タイムシェアリングへ戻る",
      href: window.location.protocol + "//" + window.location.hostname,
    },
  ]
};
