/* eslint-disable */
import Corgiria1 from "@/client/assets/images/PartySceneNew/corgiria-1.png";
import Corgiria2 from "@/client/assets/images/PartySceneNew/corgiria-2.png";
import Corgiria3 from "@/client/assets/images/PartySceneNew/corgiria-3.png";
import Corgiria4 from "@/client/assets/images/PartySceneNew/corgiria-4.png";

import Img1 from "@/client/assets/images/PartyScene005/001.png";
import Img2 from "@/client/assets/images/PartyScene005/002.png";
import Img3 from "@/client/assets/images/PartyScene005/003.png";
import Img4 from "@/client/assets/images/PartyScene005/004.png";
import Img5 from "@/client/assets/images/PartyScene005/005.png";
import Img6 from "@/client/assets/images/PartyScene005/006.png";
import Img7 from "@/client/assets/images/PartyScene005/007.png";
import Img8 from "@/client/assets/images/PartyScene005/008.png";
import Img9 from "@/client/assets/images/PartyScene005/009.png";
import Img10 from "@/client/assets/images/PartyScene005/010.png";
import Img11 from "@/client/assets/images/PartyScene005/011.png";
import Img12 from "@/client/assets/images/PartyScene005/012.png";
import Img13 from "@/client/assets/images/PartyScene005/013.png";
import Img14 from "@/client/assets/images/PartyScene005/014.png";
import Img15 from "@/client/assets/images/PartyScene005/015.png";
import Img16 from "@/client/assets/images/PartyScene005/016.png";
import Img17 from "@/client/assets/images/PartyScene005/017.png";
import Img18 from "@/client/assets/images/PartyScene005/018.png";
import Img19 from "@/client/assets/images/PartyScene005/019.png";

export const SCENE005_DATA = {
  page_name: "展示会",
  party_banner: {
    imgs: [
      {
        img_pc: Img1,
        img_mobile: Img1,
      },
    ],
    text_bold: `展示会するなら<br />
    TIME SHARINGがおすすめ！`,
    text_normal: `製品展示会、個展、ポップアップストアなど<br />
                    展示会をするならTIMESHARINGにお任せください。`,
  },
  party_services: [
    {
      img: Img2,
      title: `好立地・アクセスの良さ`,
      content: `展示会を行うならアクセスの良さは重要！<br />
      好立地でわかりやすく、来場者も迷わず来店。`,
    },
    {
      img: Img3,
      title: `空間丸ごと完全貸切`,
      content: `レンタルスペースを利用することで、空間丸ごと完全貸切で周りのテナントを気にせず出店することが可能です。`,
    },
    {
      img: Img4,
      title: `レイアウトの変更可能`,
      content: `タイムシェアリングなら、展示会の内容に合わせて様々なレイアウトに対応が可能です。`,
    },
    {
      img: Img5,
      title: `便利なケータリングプラン`,
      content: `関係者パーティーや打ち上げなどでの料理内容やプランなど、人数やパーティーの内容に合わせてカスタマイズが可能です！`,
    },
  ],

  party_query: {
    img: Img6,
    title: `展示会 会場の予約の際によくあるお困りごと`,
    list: [
      {
        desc: `展示会と打ち上げを<br />
              同じ会場で行いたいが<br />
              対応ができる会場の<br />
              探し方がわからない`,
      },
      {
        desc: `初めての展示会開催で<br />
              どのような設備が必要か<br />
              わからなくて<br />
              場所や値段だけで選ぶのは不安`,
      },
      {
        desc: `レイアウトなど変更して<br />
              展示会にオリジナリティを<br />
              出したいが<br />
              対応ができる施設を<br />
              探すのが難しい`,
      },
    ],
  },
  party_solution: {
    desc: `TIME SHARINGなら、展示会に適した施設をご案内できます！<br />
          展示会の内容に合わせて様々なレイアウト、設備のご提案、関係者パーティーや打ち上げの料理のカスタマイズなどの対応が可能です。<br />
          また、会場は見つかっても予約で埋まっていることもあり、他の施設を探すのも大変です。<br />
          こういった会場探しはタイムシェアリングにお任せください！展示会の内容を踏まえて最適な施設をご提案が可能です。`,
    img_right_sp: Img9,
    img_right: Img9,
    img_left1: Img7,
    img_left2: Img8,
    img_left1_sp: Img7,
    img_left2_sp: Img8,
  },
  party_venue: {
    title: `展示会「失敗しないための会場選び」のポイント`,
    list: [
      {
        point_label: `Point.01`,
        title: `展示会の目的とターゲットを決める`,
        desc: `どのような目的で誰向けに展示会を行うかを決めましょう。<br />
        展示会の目的とターゲットを決めて、どんな雰囲気で行いたいかを決めましょう。`,
      },
      {
        point_label: `Point.02`,
        title: `来場者の人数と快適性`,
        desc: `予想される来場者の人数を把握し、その人数に適した広さの会場を選びます。<br />
        また、来場者がアクセスしやすい場所に会場があるかどうかも重要です。`,
      },
      {
        point_label: `Point.03`,
        title: `設備とサービスの充実`,
        desc: `展示スペースやブースやテーブル、机などの備品、展示に合わせたBGMやプレゼンテーション、デモンストレーションを行うための音響設備など必要な設備が揃っているかどうか確認します。<br />
        また、関係者パーティーや打ち上げでの飲食サービスが可能かどうかもポイントです。`,
      },
    ],
    carousel_img: [Img10, Img11, Img12],
  },
  party_facilities: {
    title: `展示会開催時におすすめの設備・オプション`,
    list: [
      {
        img: Img13,
        title: `レイアウト変更`,
        desc: `展示会の内容に合わせて様々なレイアウトに対応が可能です。<br />
        無料常設備品などもご活用ください。`,
      },
      {
        img: Img14,
        title: `ワイヤレスマイク`,
        desc: `製品やサービスの説明、特徴を来場者にプレゼンテーションする際や、製品の操作方法や特性をデモンストレーションする場合にワイヤレスマイクが便利です。`,
      },
      {
        img: Img15,
        title: `控え室`,
        desc: `長時間の展示活動や来場者との交流の合間に、関係者が休憩を取る休憩室として使用できます。<br />
        他にも来場者とのプライベートな商談や打ち合わせを行うためのスペースとしても利用できます。`,
      },
      {
        img: Img16,
        title: `ケータリングプラン`,
        desc: `展示会後に関係者パーティーや打ち上げも対応可能です。ケータリングプランをご用意しておりますが、パーティーの規模や内容によって食事やドリンクをカスタマイズすることも可能となります。お電話にてご相談ください。`,
      },
    ],
  },
  party_recommend: {
    title: `展示会開催時におすすめ4会場`,
    list_ids: [622, 623, 69, 4],
    // ① Glade Park Shibuya　https://time-sharing.jp/detail/10549
    // ② Glade Park Omotesando　https://time-sharing.jp/detail/10550
    // ③ Glade Park Shinjuku　https://time-sharing.jp/detail/10126
    // ④ EBISU SHOW ROOM　https://time-sharing.jp/detail/10041
    button_label: `おすすめのパーティー<br class="sp-block" />会場をもっと見る`,
    title_portion: `展示会利用のお客様の声`,
    list: [
      {
        img: Img17,
        desc: `新商品の化粧品の展示発表会で利用しましたが、スペースの設備が充実していて、展示物を効果的に展示することができた点が良かったです。特に電源やインターネットの安定性が高かったことが助かりました。`,
      },
      {
        img: Img18,
        desc: `展示会と展示会後に関係者パーティーを開催したく飲食も可能な会場を探していたところ、なかなか要望に沿った会場が見つからず困っていた際に利用させていただきました。<br />
        会場の雰囲気も良く、パーティー用のケータリングサービスも利用でき、要望通りに開催でき、満足でした。`,
      },
      {
        img: Img19,
        desc: `個展を開催することが決まり、立地条件が良く、設備の整った会場探しをすることになりました。<br />
予算内で要望も含めた会場がなかなか見つからず、コンシェルジュに相談したところ、希望通りの会場を提案してもらい、無事予約することができました。`,
      },
    ],
  },
  party_concierge: {
    list: [
      {
        img: Corgiria1,
        title: `お電話でのご相談が可能`,
        desc: `スペースのご予約はお電話でもお受けしております。より詳細な内容をお伝えしたり、ご希望のエリアや内容に沿ったスペース探しをコンシェルジュがサポートいたします。お気軽にお問い合わせください。`,
      },
      {
        img: Corgiria2,
        title: `人数に応じたスペースをご提案`,
        desc: `1名でご利用のテレワークブース、10〜30名でご利用可能なセミナー開催できる会議室、100名以上着席可能な大規模貸し会議室、200名でご利用可能なパーティースペースまで、人数に応じた様々なスペースをご案内できます。レイアウト変更のできるスペースも多数ご用意しておりますので、ご利用用途に合わせてご提案可能です。`,
      },
      {
        img: Corgiria3,
        title: `無料内見サービスで納得がいく会場選びのお手伝い`,
        desc: `セミナーやパーティー会場など、ご予約される前に、無料内見サービスでスペースの確認が可能です。レイアウトやスペースの雰囲気を事前に確認してご予約いただけるので納得のいく会場が選べます。お気軽にお電話にてご相談ください。※一部対応不可のスペースがあります。ご了承ください。`,
      },
      {
        img: Corgiria4,
        title: `お食事内容、料金プランなどカスタマイズ可能`,
        desc: `周年記念パーティーや結婚式二次会、歓送迎会などパーティー内容や人数に合わせてお食事内容、料金プランなどカスタマイズ可能です。<br />
              カスタマイズはお電話にてコンシェルジュにご相談ください。`,
      },
    ],
  },
  party_faq: {
    list: [
      {
        question: `仮予約はできますか。`,
        answer: `仮予約の対応可能会場でございます。<br />
          お問い合わせフォームから会場名、日時、用途詳細、人数、その他条件を記載の上送信してください。<br />
          ※カレンダーが空室の場合でも仮予約が入っている場合がございます。`,
      },
      {
        question: `何分前から入室可能ですか？`,
        answer: `予約時間ちょうどからご入室可能です。<br />
          準備～撤収を含めた時間でご予約をお願いいたします。<br />
          ご予約時間より前の入室や、時間を過ぎての退室をされる場合は、必ず延長予約をお取りください。`,
      },
      {
        question: `レイアウトの変更は可能ですか？`,
        answer: `原則としてお客様ご自身でのレイアウト変更をお願いしております。<br />
        レイアウト変更をされた場合、必ず退室前に原状復帰をお願いいたします。<br />
        弊社に事前変更を依頼する場合は、別途5万円(税別)の追加料金が発生いたします。`,
      },
      {
        question: `喫煙は可能ですか？`,
        answer: `スペースによって異なります。<br />
        喫煙可能なスペースがありますので各スペースの詳細ページをご確認ください。<br />
        また、喫煙される場合はご予約時に喫煙希望の旨をお伝えください。`,
      },
      {
        question: `音響設備はありますか？`,
        answer: `スペースによって音響設備の内容は異なりますがご用意しております。<br />
          無料備品としてミキサーのご用意をしているスペースもあり、ミキサーにご持参いただいたPCやスマートフォンを接続し、音を流すことが可能なスペースもあります。<br />
          接続方法や備品の詳細についても、スペースごとに音響設備の内容が異なりますので、各スペースの詳細ページをご確認ください。`,
      },
      {
        question: `撮影利用は可能ですか？`,
        answer: `撮影での利用も可能です。ただし、撮影の内容によってはご利用いただけない場合がございます。撮影内容を記載の上お問い合わせください。<br />
          ※撮影のお客様には必ず利用前の内容確認を行わせていただきます。内容によりお受けできない場合がございますので予めご了承ください。<br />
          ※お申込み後、弊社からサイト内メッセージやメール、お電話にて内容確認のご連絡いたしますので、必ずご確認ください。`,
      },
    ],
  },
  navItem: [
    {
      title: "トップに戻る",
      href: "#",
    },
    {
      title:
        "展示会<br class='pc-block'/> 「失敗しない<br class='sp-block'/>ための会場選び」のポイント",
      href: "#main-section",
    },
    {
      title: "展示会<br class='sp-block'/>開催時におすすめの設備・オプション",
      href: "#facilities",
    },
    {
      title: "展示会開催時におすすめ4会場",
      href: "#recommend",
    },
    {
      title: "展示会利用のお客様の声",
      href: "#review",
    },
    {
      title: "お申込方法",
      href: "#apply",
    },
    {
      title: "TIME SHARINGの<br class='sp-block'/>コンシェルジュサポート",
      href: "#concierge",
    },
    {
      title: "ビジネスアカウント6つのメリット",
      href: "#business",
    },
    {
      title: "よくあるご質問",
      href: "#faq",
    },
    {
      title: "タイムシェアリング for Party にもどる",
      href:
        window.location.protocol + "//" + window.location.hostname + "/forparty",
    },
    {
      title: "タイムシェアリングへ戻る",
      href: window.location.protocol + "//" + window.location.hostname,
    },
  ]
};
