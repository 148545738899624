<template>
  <Teleport to="body">
    <div class="my-dialog" v-if="isShowDialog">
      <div class="contact-inquiries" v-click-outside="onClickOutside">
        <div class="inquiries-button-wrap">
          <b v-if="$route.params.displayId">ID：{{ $route.params.displayId }}</b>
          <p v-if="$route.params.displayId">スペースIDをお知らせください</p>
          <a v-if="isPhoneSupportable" :href="tel01" target="_blank" class="tel text-center"
            >電話（予約・空室確認）</a
          >
          <a v-if="isPhoneSupportable" :href="tel02" target="_blank" class="tel text-center"
            >電話（予約後・その他）</a
          >
          <a href="/inquiries" target="_blank" class="inquiries"
            >問い合わせフォーム</a
          >
          <p v-if="isPhoneSupportable">電話受付時間10:00~18:00</p>
          <div v-else>
            <p>現在、電話での問い合わせができません。</p>
            <p>受付時間10:00~18:00にご連絡ください。</p>
          </div>
        </div>
        <button type="button" @click="isShowDialog = false"></button>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { ref, defineProps, watch, defineEmits, computed } from "vue";

const props = defineProps({
  showDialog: Boolean,
  default: () => false,
});
const emit = defineEmits(["closeDialog"]);
const isShowDialog = ref(false);
const currentTime = ref(0);
watch(props, (value) => (isShowDialog.value = value.showDialog));
watch(isShowDialog, (value) => {
  currentTime.value = new Date().getHours();
  if (!value) emit("closeDialog");
});
const tel01 = "tel:81368008931";
const tel02 = "tel:81368008932";
const isPhoneSupportable = computed(
  () => currentTime.value >= 10 && currentTime.value <= 18
);
const onClickOutside = () => {
  if (isShowDialog.value) {
    isShowDialog.value = false;
  }
};
</script>
<style lang="scss" scoped>
.contact-inquiries {
  background: #fff;
  padding: 35px 0 20px;
  position: relative;
  border-radius: 4px;
  width: 90%;
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 0%;
    background: rgba(0, 0, 0, 0.4);
    &::before {
      font-weight: bold;
      content: "x";
      color: #fff;
      font-size: 20px;
    }
  }
  .inquiries-button-wrap {
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    .inquiries,
    .tel {
      width: 80%;
      margin: 5px auto;
      color: #fff;
      border-radius: 4px;
      padding: 10px;
      display: block;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(86, 216, 157, 0.8) 100%
      );
      text-decoration: none;
      text-align: center;
      font-size: 16px;
    }
    .tel {
      position: relative;
      text-align: left;
      font-weight: bold;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(10, 80, 201, 0.8) 100%
      );
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-image: linear-gradient(to left, #30cfd0 0%, #330867 100%);
      }
    }
  }
}
.my-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
