import { ROUTER_PATH, ROUTE_NAME, TYPE_USER, PAYMENT_STATUS } from "@/client/const";
import { auth } from "./middlewares";
import useCustomer from "../store/Customer";
import { getSpaceInfoByDisplayIdApi, getSpaceInfoBySpaceIdApi, getBusinessDetailCustomer } from "@/client/api";
import { removeToken } from "@/client/utils/authToken";
import useAuth from "@/client/store/auth";
import { SCENE002_DATA } from "../views/PartySceneCommon/const/scene002";
import { SCENE005_DATA } from "../views/PartySceneCommon/const/scene005";

const Home = () => import("@/client/pages/Home");
const MailConfirm = () => import("@/client/views/MailConfirm/MailConfirm.vue");
const UpdateInfo = () => import("@/client/views/MailConfirm/UpdateInfo.vue");
const RegisterSuccess = () =>
  import("@/client/views/MailConfirm/RegisterSuccess.vue");
const MyPagesLayout = () => import("@/client/views/MyPagesLayout");
const ReservationsRequest = () => import("@/client/views/ReservationsRequest");
const MessageMyPage = () => import("@/client/views/MessageMyPage");
const Profile = () => import("@/client/views/Profile");
const ReservationDetail = () => import("@/client/views/ReservationDetail");
const ReservationDetailOld = () => import("@/client/views/ReservationDetail/ReservationDetail.vue");
const BrowsingHistory = () => import("@/client/views/BrowsingHistory");
const ObservationRequest = () => import("@/client/views/ObservationRequest");
const ObservationDetail = () => import("@/client/views/ObservationDetail");
const CreditCard = () => import("@/client/views/CreditCardForm");
const CustomerPayment = () => import("@/client/views/CustomerPayment");
const MessageDetail = () => import("@/client/views/MessageDetail");
const DetailSpace = () => import("@/client/views/DetailSpace");
const DetailSpacePanorama = () => import("@/client/views/DetailSpacePanorama");
const ListAreaByCategory = () => import("@/client/views/ListAreaByCategory");
const ListCategoryOfCategoryHeader = () =>
  import(
    "@/client/views/ListCategoryOfCategoryHeader/ListCategoryOfCategoryHeader.vue"
  );

const SpaceResearchLocation = () =>
  import("@/client/views/SpaceResearchLocation/SpaceResearchLocation.vue");

const BookingTourOfSpace = () => import("@/client/views/BookingTourOfSpace");
const SpaceBookingCalendar = () =>
  import("@/client/views/SpaceBookingCalendar");
const ListSpaceOfArea = () => import("@/client/views/ListSpaceOfArea");
const FrequentlyQuestions = () => import("@/client/views/FrequentlyQuestions");
const ContactIquiries = () => import("@/client/views/ContactkIquiries");
import("@/client/views/SpaceResearch/SpaceResearch.vue");
const ReservationsRequestSuccess = () =>
  import("@/client/views/ReservationRequestInput/ReservationsRequestSuccess.vue");
// const ReservationsRequestInput = () =>
//   import("@/client/views/ReservationRequestInput");
const ReservationsRequestConfirm = () =>
  import("@/client/views/ReservationRequestConfirm");
// const ListCategory = () =>
//   import("@/client/views/ListCategory/ListCategory.vue");
const SpecifiedCommercial = () => import("@/client/views/SpecifiedCommercial");
import RegisterPolicy from "@/client/views/RegisterPolicy/RegisterPolicy.vue";
const CampaignDiscount = () =>
  import("@/client/views/CampaignDiscount/CampaignDiscount.vue");
// const CampaignDiscount202308 = () =>
//   import("@/client/views/CampaignDiscount/202308/CampaignDiscount.vue");
const MemberAgreement = () => import("@/client/views/MemberAgreement");
const NewsDetail = () => import("@/client/views/NewsDetail/NewsDetail.vue");
const NewsList = () => import("@/client/views/NewsList/NewsList.vue");
// const UnDescription = () => import("@/client/views/UnDescription/UnDescription.vue");
const NotFound = () => import("@/client/views/NotFound/NotFound.vue");
const SpaceBookingCalendarNew = () =>
  import("@/client/views/SpaceBookingCalendarNew");
const ReservationInformation = () => import("@/client/views/ReservationInformation");
const ReservationSuccess = () => import("@/client/views/ReservationSuccess");
const ReservationSuccessList = () => import("@/client/views/ReservationsFail/ReservationSuccessList.vue");
const ReservationsFail = () => import("@/client/views/ReservationsFail");
const FeaturePage = () => import("@/client/views/FeaturePage/FeaturePage.vue");
const BusinessAccountInvite = () => import("@/client/views/BusinessAccount");
const BusinessAccountRegister = () => import("@/client/views/BusinessAccount/CreateAccount");
const BusinessAccountRegisterSucces = () => import("@/client/views/BusinessAccount/CreateAccountSuccess");

const BusinessMasterDetail = () => import("@/client/views/BusinessAccount/MasterDetail");
const MasterReservationList = () => import("@/client/views/BusinessAccount/MasterReservationList");
const MasterGroupList = () => import("@/client/views/BusinessAccount/MasterGroupList");
const MasterDownload = () => import("@/client/views/BusinessAccount/MasterDownload");
const MasterUserList = () => import("@/client/views/BusinessAccount/MasterUserList");

const BusinessGeneralDetail = () => import("@/client/views/BusinessAccount/GeneralDetail");
const GeneralReservationList = () => import("@/client/views/BusinessAccount/GeneralReservationList");
const GeneralUserList = () => import("@/client/views/BusinessAccount/GeneralUserList");
const GeneralDownload = () => import("@/client/views/BusinessAccount/GeneralDownload");
const SpaceList = () => import("@/client/views/SpaceList/SpaceList.vue");
// const ContentCase = () => import("@/client/views/ContentCase/ContentCase.vue");
const ReservationProxy = () => import("@/client/views/ReservationProxy");

const UserGuideCategory = () => import("@/client/views/UserGuide/UserCt");
const UserGuideFaq = () => import("@/client/views/UserGuide/UserGuide");
const UserGuideFaqSearch = () => import("@/client/views/UserGuide/UserSearch");
const UserGuideFaqDetail = () => import("@/client/views/UserGuide/UserDetail");

export default [
  // ROUTER_CUSTOMER
  {
    path: ROUTER_PATH.REGISTERED,
    component: MailConfirm,
    beforeEnter: async (to, from, next) => {
      to.meta = from.meta;
      next();
    },
  },
  {
    path: ROUTER_PATH.UPDATE_INFO,
    component: UpdateInfo,
  },
  {
    path: ROUTER_PATH.REGISTERED_SUCCESS,
    component: RegisterSuccess,
  },
  {
    path: "/member-agreement",
    name: "member-agreement",
    component: RegisterPolicy,
  },
  {
    path: ROUTER_PATH.HOME,
    component: Home,
    name: ROUTE_NAME.CUSTOMER.HOME,
  },
  {
    path: ROUTER_PATH.USE_GUIDE,
    component: FrequentlyQuestions,
    name: ROUTE_NAME.CUSTOMER.USE_GUIDE,
    meta: {
      title: 'よくある質問|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングのよくある質問。会場探しに迷ったら、使い方ガイドをご確認ください。公式サイトの使い方、会場の探し方が分かります。公式サイトだからできる最低価格保証で、用途別にお得な貸し会議室・レンタルスペース・イベント会場を予約できます！当日予約にも対応。',
    },
  },
  {
    path: ROUTER_PATH.SPECIFIED_COMMERCIAL,
    component: SpecifiedCommercial,
    meta: {
      title: '特定商取引法に基づく表記|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングの特定商取引法に基づく表記。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。',
    }
  },
  {
    path: ROUTER_PATH.MEMBER_AGREEMENT,
    component: MemberAgreement,
    meta: {
      title: '会員規約',
      description: 'タイムシェアリングの会員規約。テレワークスペースや会議室、セミナー会場、他にも撮影場所や交流会など、利用用途別に格安価格でレンタルスペースを予約できます。1人でも大人数でも利用可能！',
    }
  },
  {
    path: ROUTER_PATH.DETAIL + "/:displayId" + "/preview",
    component: DetailSpace,
    name: ROUTE_NAME.CUSTOMER.SPACE_DETAIL_PREVIEW,
    beforeEnter: async (to, from, next) => {
      let result = await getSpaceInfoByDisplayIdApi(to.params.displayId);
      if(result.status === "published") {
        next(ROUTER_PATH.DETAIL + "/" + to.params.displayId);
      }
      // @MEMO noIndexタグコメントアウトのため
      // }else{
      //   to.meta = {
      //     detailSpace: result,
      //     robots: 'noindex, nofollow'
      //     };
      //     next();
    },
    meta: {
      title: '',
      description: '',
    }
  },
  {
    path: ROUTER_PATH.DETAIL + "/:displayId",
    component: DetailSpace,
    name: ROUTE_NAME.CUSTOMER.SPACE_DETAIL,
    beforeEnter: async (to, from, next) => {
      let result = await getSpaceInfoByDisplayIdApi(to.params.displayId);
      if(result.status === "published") {
        to.meta = { detailSpace: result };
        next();
      }
      // @MEMO noIndexタグコメントアウトのため
      //}else{
      //  to.meta = {
      //    detailSpace: result,
      //    robots: 'noindex, nofollow'
      //    };
      //    next();
    },
    meta: {
      title: '',
      description: '',
    }
  },
  {
    path: ROUTER_PATH.PANORAMA,
    component: DetailSpacePanorama,
    name: ROUTE_NAME.CUSTOMER.SPACE_PANORAMA,
  },
  {
    path: ROUTER_PATH.SPACE_BOOKING_CALENDAR,
    component: SpaceBookingCalendar,
    name: ROUTE_NAME.CUSTOMER.SPACE_BOOKING_CALENDAR,
    meta: {
      title: '予約カレンダー',
    }
  },
  {
    path: ROUTER_PATH.SPACE_BOOKING_CALENDAR_NEW,
    component: NotFound,
    name: 'space-calendar-old',
    beforeEnter: async (to, from, next) => {
      let result = await getSpaceInfoBySpaceIdApi(to.query.id);
      if(result?.display_id) {
        next(`${ROUTER_PATH.SPACE_BOOKING_CALENDAR_NEW}/${result?.display_id}`);
      } else {
        next();
      }
    },
  },
  {
    path: ROUTER_PATH.SPACE_BOOKING_CALENDAR_NEW + "/:displayId",
    component: SpaceBookingCalendarNew,
    name: ROUTE_NAME.CUSTOMER.SPACE_BOOKING_CALENDAR_NEW,
    beforeEnter: async (to, from, next) => {
      let result = await getSpaceInfoByDisplayIdApi(to.params.displayId);
      to.meta = {
        detailSpace: result,
      };
      if(result?.calendar_collaboration_toGcalId) {
        next();
      } else {
        next(`${ROUTER_PATH.NOT_FOUND}`);
      }
    },
    meta: {
      title: '予約カレンダー',
    }
  },
  {
    path: ROUTER_PATH.RESERVATION_INFORMATION + "/:displayId",
    component: ReservationInformation,
    name: ROUTE_NAME.CUSTOMER.RESERVATION_INFORMATION,
    meta: {
      title: '予約カレンダー',
    }
  },
  {
    path: ROUTER_PATH.RESERVATION_INFORMATION_SUCCESS + "/:successType",
    component: ReservationSuccess,
    name: ROUTE_NAME.CUSTOMER.RESERVATION_INFORMATION_SUCCESS,
    meta: {
      title: '予約完了',
    }
  },
  {
    path: ROUTER_PATH.RESERVATION_INFORMATION_FAIL + "/:displayId",
    component: ReservationsFail,
    name: ROUTE_NAME.CUSTOMER.RESERVATION_INFORMATION_FAIL,
    meta: {
      title: '予約完了',
    }
  },
  {
    path: ROUTER_PATH.RESERVATION_INFORMATION_SUCCESS_LIST + "/:displayId",
    component: ReservationSuccessList,
    name: ROUTE_NAME.CUSTOMER.RESERVATION_INFORMATION_SUCCESS_LIST,
    meta: {
      title: '予約完了',
    }
  },
  {
    path: ROUTER_PATH.CONTENT_CASE,
    component: ListAreaByCategory,
    name: ROUTE_NAME.CUSTOMER.CONTENT_CASE,
    meta: {
      title: '用途別|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペースを予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '/img/locationKanto.e8938c68.jpg',
      title_fb: '用途別|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペースを予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '/img/locationKanto.e8938c68.jpg',
      title_tw: '用途別|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペースを予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '/img/locationKanto.e8938c68.jpg',
    }
  },
  {
    path: ROUTER_PATH.CONTENT_LOCATION_HEADER,
    component: ListCategoryOfCategoryHeader,
    name: ROUTE_NAME.CUSTOMER.CONTENT_LOCATION_HEADER,
    meta: {
      title: '人気地域|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペース・イベント会場を予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '/img/locationKanto.e8938c68.jpg',
      title_fb: '人気地域|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペースを予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '/img/locationKanto.e8938c68.jpg',
      title_tw: '人気地域|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'カンタンネット予約！公式サイトだからできる最低価格保証で、希望エリアの、お得な貸し会議室・レンタルスペースを予約できます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '/img/locationKanto.e8938c68.jpg',
    }
  },
  {
    path: ROUTER_PATH.CONTENT_LOCATION_HEADER + "/:area_slug",
    component: SpaceResearchLocation,
    name: ROUTE_NAME.CUSTOMER.CONTENT_LOCATION_LIST,
    meta: {
      title: '|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'にあるタイムシェアリングの貸し会議室・レンタルスペース・イベント会場の中から、会議・セミナーで使える会場を、カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場をご予約ください。',
      op_img: '',
      title_fb: '|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'にあるタイムシェアリングの貸し会議室・レンタルスペースの中から、会議・セミナーで使える会場を、カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースをご予約ください。',
      img_fb: '',
      title_tw: '|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'にあるタイムシェアリングの貸し会議室・レンタルスペースの中から、会議・セミナーで使える会場を、カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースをご予約ください。',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.SPACE_TOUR,
    component: BookingTourOfSpace,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    name: ROUTE_NAME.CUSTOMER.SPACE_TOUR,
  },
  {
    path: ROUTER_PATH.MY_PAGES,
    component: MyPagesLayout,
    name: ROUTE_NAME.CUSTOMER.MY_PAGE,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    children: [
      {
        path: ROUTER_PATH.RESERVATIONS,
        component: ReservationsRequest,
        meta: {
          middleware: [(context) => auth(context, TYPE_USER.USER)],
          title: '予約・予約リクエスト',
        },
        name: ROUTE_NAME.CUSTOMER.RESERVATIONS,
      },
      {
        path: `${ROUTER_PATH.RESERVATIONS_DETAIL}/:id`,
        component: ReservationDetail,
        meta: {
          title: '予約・予約リクエスト',
        },
      },
      {
        path: `reservations/view-old/:id`,
        component: ReservationDetailOld,
        meta: {
          title: '予約・予約リクエスト',
        },
      },
      {
        path: ROUTER_PATH.BROWSING_HISTORY,
        component: BrowsingHistory,
        meta: {
          title: 'お気に入り・履歴',
        },
      },
      {
        path: ROUTER_PATH.TOUR_REQUEST,
        component: ObservationRequest,
        name: ROUTE_NAME.CUSTOMER.TOUR_REQUEST,
        meta: {
          title: '見学リクエスト一覧',
        },
      },
      {
        path: ROUTER_PATH.TOUR_REQUEST_DETAIL,
        name: ROUTE_NAME.CUSTOMER.TOUR_REQUEST_DETAIL,
        component: ObservationDetail,
        beforeEnter: async (to, from, next) => {
          const id = to.params.id;
          const store = useCustomer();
          await store.fetchTourDetail(id);
          next();
        },
      },
      {
        path: ROUTER_PATH.INQUIRY,
        component: MessageMyPage,
        name: ROUTE_NAME.CUSTOMER.INQUIRY,
        meta: {
          title: 'お問合せ',
        },
      },
      {
        path: ROUTER_PATH.INQUIRY_DETAIL,
        component: MessageDetail,
        name: ROUTE_NAME.CUSTOMER.INQUIRY_DETAIL,
      },
      {
        path: ROUTER_PATH.PROFILE,
        component: Profile,
        name: ROUTE_NAME.CUSTOMER.PROFILE,
        meta: {
          title: '会員プロフィール',
        },
      },
      {
        path: ROUTER_PATH.CREDIT_CARD,
        component: CreditCard,
        meta: {
          title: 'クレジットカード情報',
        },
      },
      {
        path: ROUTER_PATH.CUSTOMER_PAYMENT,
        component: CustomerPayment,
      },
      {
        path: `${ROUTER_PATH.RESERVATIONS_REQUEST_SUCCESS}/:reservationId/:spaceId`,
        component: ReservationsRequestSuccess,
        meta: {
          middleware: [(context) => auth(context, TYPE_USER.USER)],
          title: '予約完了',
        },
        name: ROUTE_NAME.CUSTOMER.RESERVATIONS_REQUEST_SUCCESS
      },
      // {
      //   path: ROUTER_PATH.UN_DESCRIPTION,
      //   component: UnDescription,
      // },
    ],
  },
  {
    path: ROUTER_PATH.CONTENT_LOCATION_SPACE,
    component: ListSpaceOfArea,
  },
  {
    path: ROUTER_PATH.RESEARCH_SPACE,
    component: SpaceList,
    meta: {
      title: '検索結果一覧',
    },
  },
  {
    path: ROUTER_PATH.RESEARCH_SPACE_LOCATION,
    component: SpaceResearchLocation,
  },
  {
    path: ROUTER_PATH.CONTACT_IQUIRIES,
    component: ContactIquiries,
    meta: {
      title: 'お問合せ|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングのお問合せフォームです。会場探しに迷ったら、公式サイトからお問い合わせください。用途に合わせて、お得な貸し会議室・レンタルスペース・イベント会場を予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      op_img: '',
      title_fb: 'お問合せ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングのお問合せフォームです。会場探しに迷ったら、公式サイトからお問い合わせください。用途に合わせて、お得な貸し会議室・レンタルスペースを予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_fb: '',
      title_tw: 'お問合せ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングのお問合せフォームです。会場探しに迷ったら、公式サイトからお問い合わせください。用途に合わせて、お得な貸し会議室・レンタルスペースを予約しましょう！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.RESERVATIONS_REQUEST_INPUT,
    component: SpaceBookingCalendar,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
      title: '予約内容入力',
    },
    name: ROUTE_NAME.CUSTOMER.RESERVATIONS_REQUEST_INPUT,
    beforeEnter: async (to, from, next) => {
      const store = useCustomer();
      await store.fetchCustomerInfo();
      to.meta = from.meta;
      next();
    },
  },
  {
    path: ROUTER_PATH.RESERVATIONS_REQUEST_CONFIRM,
    component: ReservationsRequestConfirm,
  },
  {
    path: "/credit-pre-reserved",
    component: () =>
      import("@/client/views/ReservationRequestConfirm/RequestCreditCard.vue"),
    name: ROUTE_NAME.CUSTOMER.CREDIT_PRE_RESERVED,
    beforeEnter: async (to, from, next) => {
      if (to.query.reservationId) {
        const store = useCustomer();
        await store.fetchReservationInfo(to.query.reservationId);
        to.meta = {
          ...to.meta,
          reservationInfo: store.infoCheckReservation
        };
      } else {
        to.meta = {
          ...to.meta,
          reservationInfo: from.meta.reservationInfo
        };
      }
      next();
    },
    meta: {
      title: 'クレジットカード情報',
    },
  },
  {
    path: ROUTER_PATH.CONTENT_CASE,
    component: () =>
      import("@/client/components/ComponentSearchBy/ComponentSearchBy.vue"),
    name: ROUTE_NAME.CUSTOMER.CONTENT_CASE,
    meta: {
      title: '用途別|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'カンタンネット予約！公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペース・イベント会場を探せます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '',
      title_fb: '用途別|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'カンタンネット予約！公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを探せます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: '用途別|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'カンタンネット予約！公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを探せます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: "/contents/case/:categorySlug",
    component: () =>
      import("@/client/components/ComponentSearchBy/ComponentSearchByCategory.vue"),
    name: ROUTE_NAME.CUSTOMER.CONTENT_CASE_LIST,
    meta: {
      title: '|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'で使える貸し会議室・レンタルスペースの予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      op_img: '',
      title_fb: '|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'で使える貸し会議室・レンタルスペースの予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: '|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'で使える貸し会議室・レンタルスペースの予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: "/contents/case/:categorySlug/:areaSlug",
    component: SpaceList,
    name: ROUTE_NAME.CUSTOMER.CONTENT_CASE_DETAIL_SPACE_LIST,
    meta: {
      title: 'スペース',
      description: 'で使える貸し会議室・レンタルスペース・イベント会場の予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！希望に合った時間で会場予約。当日予約もOK！',
      title_fb: 'スペース',
      description_fb: 'で使える貸し会議室・レンタルスペースの予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！希望に合った時間で会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: 'スペース',
      description_tw: 'で使える貸し会議室・レンタルスペースの予約ができます！公式サイトだからできる最低価格保証。無料で使い放題の設備・備品と、有料サービスでもっと便利に！希望に合った時間で会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.CAMPAIGN_DISCOUNT,
    component: CampaignDiscount,
    meta: {
      title: 'Newスペースキャンペーン',
      description: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '/img/202308.75e54fda.jpeg',
      title_fb: 'Newスペースキャンペーン',
      description_fb: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '/img/202308.75e54fda.jpeg',
      title_tw: 'Newスペースキャンペーン',
      description_tw: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '/img/202308.75e54fda.jpeg',
    }
  },
  // {
  //   path: ROUTER_PATH.CAMPAIGN_DISCOUNT202308,
  //   component: CampaignDiscount202308,
  //   meta: {
  //     title: 'Newスペースキャンペーン',
  //     description: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
  //     op_img: '/img/202308.75e54fda.jpeg',
  //     title_fb: 'Newスペースキャンペーン',
  //     description_fb: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
  //     img_fb: '/img/202308.75e54fda.jpeg',
  //     title_tw: 'Newスペースキャンペーン',
  //     description_tw: '限定！貸し会議室・レンタルスペースのオープニングキャンペーンで、お得に予約できます。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
  //     img_tw: '/img/202308.75e54fda.jpeg',
  //   }
  // },
  {
    path: ROUTER_PATH.NEWS_DETAIL,
    component: NewsDetail,
    meta: {
      title: 'お知らせ|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '最新のオープン情報、キャンペーン情報が確認できます。公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場を予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      op_img: '',
      title_fb: 'お知らせ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: '最新のオープン情報、キャンペーン情報が確認できます。公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースを予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: 'お知らせ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: '最新のオープン情報、キャンペーン情報が確認できます。公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペースを予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！用途別に、希望に合った時間で会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.NEWS_LIST,
    component: NewsList,
    meta: {
      title: 'お知らせ一覧|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングからのお知らせや、最新のオープン情報が確認できます。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペース・イベント会場を予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      op_img: '',
      title_fb: 'お知らせ一覧|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングからのお知らせや、最新のオープン情報が確認できます。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      img_fb: '',
      title_tw: 'お知らせ一覧|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングからのお知らせや、最新のオープン情報が確認できます。公式サイトだからできる最低価格保証で、用途別に、お得な貸し会議室・レンタルスペースを予約しましょう。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      img_tw: '',
    }
  },
  {
    path: '/space/reserved/:reservationId/:spaceId/:date/choose_payment',
    component: ReservationProxy,
    name: ROUTE_NAME.CUSTOMER.CUSTOMER_CHOOSE_PAYMENT_METHOD,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    beforeEnter: async (to, from, next) => {
      const reservationId = to.params.reservationId;
      const store = useCustomer();
      await store.fetchReservationInfo(reservationId);
      await store.fetchCustomerInfo();
      if (store.getInfoCheckReservation === null) {
        next(ROUTER_PATH.MY_PAGES + '/' + ROUTER_PATH.RESERVATIONS);
      } else {
        if (store.getInfoCheckReservation.customer_id != store.getInfoCustomer.id || store.getInfoCheckReservation.status != PAYMENT_STATUS.RESERVATION_BY_PROXY_SELECT_METHOD) {
          next(ROUTER_PATH.MY_PAGES + '/' + ROUTER_PATH.RESERVATIONS);
        } else {
          return next();
        }
      }
    },
  },
  {
    path: '/space/reserved/:reservationId/:spaceId/:date/choose_payment_old',
    component: () => import('@/client/views/ReservationRequestInput/CustomerChoosePaymentMethod.vue'),
    name: ROUTE_NAME.CUSTOMER.CUSTOMER_CHOOSE_PAYMENT_METHOD_OLD,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    beforeEnter: async (to, from, next) => {
      const reservationId = to.params.reservationId;
      const store = useCustomer();
      await store.fetchReservationInfo(reservationId);
      await store.fetchCustomerInfo();
      if (store.getInfoCheckReservation === null) {
        next(ROUTER_PATH.MY_PAGES + '/' + ROUTER_PATH.RESERVATIONS);
      } else {
        if (store.getInfoCheckReservation.customer_id != store.getInfoCustomer.id || store.getInfoCheckReservation.status != PAYMENT_STATUS.RESERVATION_BY_PROXY_SELECT_METHOD) {
          next(ROUTER_PATH.MY_PAGES + '/' + ROUTER_PATH.RESERVATIONS);
        } else {
          return next();
        }
      }
    },
  },
  {
    path: '/space/:spaceId/cancellation-policy',
    component: () => import('@/client/views/ReservationRequestInput/CancellationPolicyPage.vue'),
    name: ROUTE_NAME.CUSTOMER.CANCELLATION_POLICY
  },
  {
    path: '/space/:spaceId/terms-of-use',
    component: () => import('@/client/views/ReservationRequestInput/TermOfUse.vue'),
    name: ROUTE_NAME.CUSTOMER.TERM_OF_USE
  },
  {
    path: ROUTER_PATH.LP_GLADE_PARK_SHINJUKU,
    component: () => import('@/client/views/LpGradeParkShinjuku/LpGradeParkShinjuku.vue'),
    name: ROUTE_NAME.LP.LP_GLADE_PARK_SHINJUKU,
    meta: {
      title: 'グレイドパーク新宿（Glade Park 新宿）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク新宿（Glade Park 新宿）は、西新宿駅徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク新宿（Glade Park 新宿）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク新宿（Glade Park 新宿）は、西新宿駅徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク新宿（Glade Park 新宿）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク新宿（Glade Park 新宿）は、西新宿駅徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_HOW_TO_USE,
    component: () => import('@/client/views/LpHowToUse/HowToUse.vue'),
    name: ROUTE_NAME.LP.HOW_TO_USE,
    meta: {
      title: '初めての方|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'カンタンネット予約！タイムシェアリングは、初めての方でも、簡単に貸し会議室・レンタルスペース・イベント会場を予約することができます。こちらのページは初めての方向けに、利用方法や予約手続きについて、分かりやすく解説しています。お問合せはオンラインでもお電話でも対応しています。',
      op_img: '/img/topvisual.259265eb.jpg',
      title_fb: '初めての方|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'カンタンネット予約！タイムシェアリングは、初めての方でも、簡単に貸し会議室・レンタルスペースを予約することができます。こちらのページは初めての方向けに、利用方法や予約手続きについて、分かりやすく解説しています。お問合せはオンラインでもお電話でも対応しています。',
      img_fb: '/img/topvisual.259265eb.jpg',
      title_tw: '初めての方|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'カンタンネット予約！タイムシェアリングは、初めての方でも、簡単に貸し会議室・レンタルスペースを予約することができます。こちらのページは初めての方向けに、利用方法や予約手続きについて、分かりやすく解説しています。お問合せはオンラインでもお電話でも対応しています。',
      img_tw: '/img/topvisual.259265eb.jpg',
    }
  },
  {
    path: ROUTER_PATH.LP_CATERING,
    component: () => import('@/client/views/LpCatering/LpCatering.vue'),
    name: ROUTE_NAME.LP.CATERING,
    meta: {
      title: 'ケータリング|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングのケータリングページです。食事からアルコールを含むドリンクの提供まで行っています。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      op_img: '',
      title_fb: 'ケータリング|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'タイムシェアリングのケータリングページです。食事からアルコールを含むドリンクの提供まで行っています。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_fb: '',
      title_tw: 'ケータリング|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'タイムシェアリングのケータリングページです。食事からアルコールを含むドリンクの提供まで行っています。公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスでもっと便利に！当日予約も対応しています。',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_SPACE_SAFETY,
    component: () => import('@/client/views/LpSharing/SpaceSafety.vue'),
    name: ROUTE_NAME.LP.SPACE_SAFETY,
    meta: {
      title: 'スペース安心補償|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '貸し会議室やレンタルスペースを借りる際に「備品を壊してしまったらどうしよう。」と不安に感じたことはありませんか？そんな時は、スペース安心補償をご利用すれば問題無しです！公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！',
      op_img: '',
      title_fb: 'スペース安心補償|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '貸し会議室やレンタルスペースを借りる際に「備品を壊してしまったらどうしよう。」と不安に感じたことはありませんか？そんな時は、スペース安心補償をご利用すれば問題無しです！公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！',
      img_fb: '',
      title_tw: 'スペース安心補償|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '貸し会議室やレンタルスペースを借りる際に「備品を壊してしまったらどうしよう。」と不安に感じたことはありませんか？そんな時は、スペース安心補償をご利用すれば問題無しです！公式サイトだからできる最低価格保証で、用途別にお得に貸し会議室・レンタルスペース・イベント会場の予約ができます！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_CONCIERGE,
    component: () => import('@/client/views/LpConciergeService/ConciergeService.vue'),
    name: ROUTE_NAME.LP.CONCIERGE,
    meta: {
      title: 'コンシェルジュ|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '会場を探すのは大変・・・そんな時はコンシェルジュにお任せください！人数、エリア、利用用途に合わせ、価値ある時間を過ごしていただける最適な会場をご提案します。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      op_img: '/img/topvisual.a3a17c8f.jpg',
      title_fb: 'コンシェルジュ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: '会場を探すのは大変・・・そんな時はコンシェルジュにお任せください！人数、エリア、利用用途に合わせ、価値ある時間を過ごしていただける最適な会場をご提案します。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      img_fb: '/img/topvisual.a3a17c8f.jpg',
      title_tw: 'コンシェルジュ|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: '会場を探すのは大変・・・そんな時はコンシェルジュにお任せください！人数、エリア、利用用途に合わせ、価値ある時間を過ごしていただける最適な会場をご提案します。無料で使い放題の設備・備品と、有料サービスで、もっと便利に！当日予約も対応しています。',
      img_tw: '/img/topvisual.a3a17c8f.jpg',
    }
  },
  {
    path: ROUTER_PATH.LP_CORPORATE_USE,
    component: () => import("@/client/views/BusinessAccount/LpBusinessPage"),
    name: ROUTE_NAME.LP.CORPORATE_USE,
    meta: {
      title: '法人向け|【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'タイムシェアリングは、法人の希望条件に合う貸し会議室・レンタルスペース・イベント会場を、簡単に予約することができます。会場を探すのは大変・・・そんな時は専任のコンシェルジュが、人数、エリア、利用用途に合わせ、最適な会場をご提案します。見積書、請求書、領収書も対応しています。',
      op_img: '/img/topvisual.fbd0040e.jpg',
      title_fb: '法人向け|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_fb: 'タイムシェアリングは、法人の希望条件に合う貸し会議室・レンタルスペースを、簡単に予約することができます。会場を探すのは大変・・・そんな時は専任のコンシェルジュが、人数、エリア、利用用途に合わせ、最適な会場をご提案します。見積書、請求書、領収書も対応しています。',
      img_fb: '/img/topvisual.fbd0040e.jpg',
      title_tw: '法人向け|【公式】貸し会議室・レンタルスペースならタイムシェアリング',
      description_tw: 'タイムシェアリングは、法人の希望条件に合う貸し会議室・レンタルスペースを、簡単に予約することができます。会場を探すのは大変・・・そんな時は専任のコンシェルジュが、人数、エリア、利用用途に合わせ、最適な会場をご提案します。見積書、請求書、領収書も対応しています。',
      img_tw: '/img/topvisual.fbd0040e.jpg',
    }
  },
  {
    path: ROUTER_PATH.LP_GRADE_PARK_SHIBUYA,
    component: () => import('@/client/views/LpGradeParkShipuya/LpGradeParkShipuya.vue'),
    name: ROUTE_NAME.LP_GRADE_PARK_SHIBUYA,
    meta: {
      title: 'グレイドパーク 渋谷（Glade Park 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク渋谷（Glade Park 渋谷）は、渋谷駅徒歩1分の好立地！最大収容人数350人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク 渋谷（Glade Park 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク渋谷（Glade Park 渋谷）は、渋谷駅徒歩1分の好立地！最大収容人数350人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク 渋谷（Glade Park 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク渋谷（Glade Park 渋谷）は、渋谷駅徒歩1分の好立地！最大収容人数350人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_MACE_IKEBUKURO,
    component: () => import('@/client/views/LpMaceIkebukuro/LpMaceIkebukuro.vue'),
    name: ROUTE_NAME.LP.MACE_IKEBUKURO,
    meta: {
      title: 'Mace 池袋【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'Mace 池袋は、池袋駅徒歩3分の好立地！最大収容人数50人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'Mace 池袋【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'Mace 池袋は、池袋駅徒歩3分の好立地！最大収容人数50人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'Mace 池袋【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'Mace 池袋は、池袋駅徒歩3分の好立地！最大収容人数50人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_EBISU_SHOW_ROOM,
    component: () => import('@/client/views/LpEbisuShowRoom/LpEbisuShowRoom.vue'),
    name: ROUTE_NAME.LP.EBISU_SHOW_ROOM,
    meta: {
      title: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）は、JR各線恵比寿駅西口より徒歩5分の好立地！最大収容人数130人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）は、JR各線恵比寿駅西口より徒歩5分の好立地！最大収容人数130人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク恵比寿（旧：EBISU SHOW ROOM［エビスショールーム］）は、JR各線恵比寿駅西口より徒歩5分の好立地！最大収容人数130人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.FOR_PARTY,
    component: () => import('@/client/views/ForParty'),
    name: ROUTE_NAME.FOR_PARTY,
    meta: {}
  },
  {
    path: ROUTER_PATH.LP_GLADE_PARK_OMOTESANDO,
    component: () => import('@/client/views/LpGradeParkOmotesando/LpGradeParkOmotesando.vue'),
    name: ROUTE_NAME.LP.GLADE_PARK_OMOTESANDO,
    meta: {
      title: 'グレイドパーク 表参道（Glade Park 表参道）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク表参道（Glade Park 表参道）は、銀座線表参道駅A3番出口より徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク 表参道（Glade Park 表参道）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク表参道（Glade Park 表参道）は、銀座線表参道駅A3番出口より徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク 表参道（Glade Park 表参道）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク表参道（Glade Park 表参道）は、銀座線表参道駅A3番出口より徒歩2分の好立地！最大収容人数120人で大型イベント開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.ELLARE,
    component: () => import('@/client/views/LpEllare/LpEllare.vue'),
    name: ROUTE_NAME.LP.ELLARE,
    meta: {
      title: 'グレイドパーク池袋（旧：ELLARE）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク池袋（旧：ELLARE）は、池袋駅徒歩5分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク池袋（旧：ELLARE）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク池袋（旧：ELLARE）は、池袋駅徒歩5分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク池袋（旧：ELLARE）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク池袋（旧：ELLARE）は、池袋駅徒歩5分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.LP_MACE_SENDAGAYA,
    component: () => import('@/client/views/LpMaceSendagaya/LpMaceSendagaya.vue'),
    name: ROUTE_NAME.LP.MACE_SENDAGAYA,
    meta: {
      title: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）は、千駄ヶ谷駅徒歩7分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）は、千駄ヶ谷駅徒歩7分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク千駄ヶ谷（旧：Mace千駄ヶ谷）は、千駄ヶ谷駅徒歩7分の好立地！最大収容人数80人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.LP_MACE_MINAMIAOYAMA,
    component: () => import('@/client/views/LpMaceMinamiaoyama/LpMaceMinamiaoyama.vue'),
    name: ROUTE_NAME.LP.MACE_MINAMIAOYAMA,
    meta: {
      title: 'グレイドパーク南青山（旧：Mace南青山）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク南青山（旧：Mace南青山）は、外苑前駅徒歩5分の好立地！最大収容人数39人でイベント・パーティ開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク南青山（旧：Mace南青山）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク南青山（旧：Mace南青山）は、外苑前駅徒歩5分の好立地！最大収容人数39人でイベント・パーティ開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク南青山（旧：Mace南青山）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク南青山（旧：Mace南青山）は、外苑前駅徒歩5分の好立地！最大収容人数39人でイベント・パーティ開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.LP_LOUNGE_R_TERRACE_SHIBUYA,
    component: () => import('@/client/views/LpLoungeRTerraceShibuya/LpLoungeRTerraceShibuya.vue'),
    name: ROUTE_NAME.LP.LOUNGE_R_TERRACE_SHIBUYA,
    meta: {
      title: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）は、渋谷駅徒歩8分の好立地！最大収容人数30人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）は、渋谷駅徒歩8分の好立地！最大収容人数30人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'グレイドパーク渋谷テラス（旧：Lounge-R TERRACE 渋谷）は、渋谷駅徒歩8分の好立地！最大収容人数30人でイベントやパーティの開催もOK！カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    },
  },
  {
    path: ROUTER_PATH.PARTY.SCENE005,
    component: () => import('@/client/views/PartySceneCommon/PartySceneCommon.vue'),
    name: ROUTE_NAME.PARTY.SCENE005,
    meta: {
      title: '展示会【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '展示会ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: '展示会【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '展示会ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: '展示会【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '展示会ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
      SCENE_DATA: SCENE005_DATA
    },
  },
  {
    path: ROUTER_PATH.PARTY.SCENE002,
    component: () => import('@/client/views/PartySceneCommon/PartySceneCommon.vue'),
    name: ROUTE_NAME.PARTY.SCENE002,
    meta: {
      title: '歓送迎会、懇親会、同窓会などのパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '歓送迎会、懇親会、同窓会などのパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: '歓送迎会、懇親会、同窓会などのパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '歓送迎会、懇親会、同窓会などのパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: '歓送迎会、懇親会、同窓会などのパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '歓送迎会、懇親会、同窓会などのパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
      SCENE_DATA: SCENE002_DATA
    },
  },
  {
    path: ROUTER_PATH.PARTY.SCENE001,
    component: () => import('@/client/views/PartyScene/PartySceneNew.vue'),
    name: ROUTE_NAME.PARTY.SCENE001,
    meta: {
      title: '周年記念パーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '周年記念パーティーならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: '周年記念パーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '周年記念パーティーならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: '周年記念パーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '周年記念パーティーならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.PARTY.SCENE003,
    component: () => import('@/client/views/PartyScene/PartyScene003.vue'),
    name: ROUTE_NAME.PARTY.SCENE003,
    meta: {
      title: '結婚式二次会のパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '結婚式二次会のパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: '結婚式二次会のパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '結婚式二次会のパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: '結婚式二次会のパーティー利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '結婚式二次会のパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.PARTY.SCENE004,
    component: () => import('@/client/views/PartyScene/PartyScene004.vue'),
    name: ROUTE_NAME.PARTY.SCENE004,
    meta: {
      title: 'セミナー・アフターパーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: 'セミナー・アフターパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: 'セミナー・アフターパーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: 'セミナー・アフターパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: 'セミナー・アフターパーティー【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: 'セミナー・アフターパーティー利用ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: ROUTER_PATH.PARTY.SCENE006,
    component: () => import('@/client/views/PartyScene/PartyScene006.vue'),
    name: ROUTE_NAME.PARTY.SCENE006,
    meta: {
      title: '撮影会・TV収録・ロケ撮影の利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description: '撮影会・TV収録・ロケ撮影ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      op_img: '',
      title_fb: '撮影会・TV収録・ロケ撮影の利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_fb: '撮影会・TV収録・ロケ撮影ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_fb: '',
      title_tw: '撮影会・TV収録・ロケ撮影の利用【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング',
      description_tw: '撮影会・TV収録・ロケ撮影ならTIME SHARINGにおまかせ！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！当日予約もOK！',
      img_tw: '',
    }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
  },
  {
    path: ROUTER_PATH.FEATURE_PAGE + "/:id",
    component: FeaturePage,
    name: ROUTE_NAME.CUSTOMER.FEATURE_PAGE,
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.INVITE,
    component: BusinessAccountInvite,
    meta: {},
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.REGISTER,
    component: BusinessAccountRegister,
    meta: {},
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.REGISTER_SUCCESS,
    component: BusinessAccountRegisterSucces,
    meta: {},
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.MASTER_DETAIL,
    component: BusinessMasterDetail,
    beforeEnter: async (to, from, next) => {
      let data = localStorage.getItem('business');
      if(!data){
        next(ROUTER_PATH.MY_PAGES);
      }else{
        data = JSON.parse(data);
        if(data.role === 'master' || data.role === 'admin'){
          next();
        }else{
          next(ROUTER_PATH.MY_PAGES);
        }
      }
    },
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.MASTER_RESERVATION,
    component: MasterReservationList,
    beforeEnter: async (to, from, next) => {
      let data = localStorage.getItem('business');
      if(!data){
        removeToken(TYPE_USER.USER);
        localStorage.removeItem('business')
        sessionStorage.removeItem("redirectParameters");
        const authState = useAuth();
        authState.isAuthenticated = false;
        next(ROUTER_PATH.LOGIN);
      }else{
        data = JSON.parse(data);
        if(data.role === 'master' || data.role === 'admin'){
          next();
        }else{
          next(ROUTER_PATH.MY_PAGES);
        }
      }
    },
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.GENERAL_DETAIL,
    component: BusinessGeneralDetail,
    beforeEnter: async (to, from, next) => {
      const store = useCustomer();
      store.isLoading = true
      const groupId = to.query?.groupId;
      try {
        let result = await getBusinessDetailCustomer({ groupId });
        to.meta = {
          businessCustomerInfo: result,
        };
        next();
      } catch (errors) {
        next(ROUTER_PATH.BUSINESSACCOUNT.GENERAL_DETAIL + '/not-found');
      } finally {
        store.isLoading = false
      }
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.GENERAL_RESERVATION,
    component: GeneralReservationList,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    beforeEnter: async (to, from, next) => {
      const store = useCustomer();
      store.isLoading = true
      const groupId = to.query?.groupId;
      try {
        let result = await getBusinessDetailCustomer({ groupId });
        to.meta = {
          businessCustomerInfo: result,
        };
        next();
      } catch (errors) {
        next(ROUTER_PATH.BUSINESSACCOUNT.GENERAL_RESERVATION + '/not-found');
      } finally {
        store.isLoading = false
      }
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.GENERAL_USER_LIST,
    component: GeneralUserList,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    beforeEnter: async (to, from, next) => {
      const store = useCustomer();
      store.isLoading = true
      const groupId = to.query?.groupId;
      try {
        let result = await getBusinessDetailCustomer({ groupId });
        to.meta = {
          businessCustomerInfo: result,
        };
        next();
      } catch (errors) {
        next(ROUTER_PATH.BUSINESSACCOUNT.GENERAL_USER_LIST + '/not-found');
      } finally {
        store.isLoading = false
      }
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.GENERAL_DOWNLOAD,
    component: GeneralDownload,
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
    beforeEnter: async (to, from, next) => {
      const store = useCustomer();
      store.isLoading = true
      const groupId = to.query.groupId;
      try {
        let result = await getBusinessDetailCustomer({ groupId });
        to.meta = {
          businessCustomerInfo: result,
        };
        next();
      } catch (errors) {
        next(ROUTER_PATH.BUSINESSACCOUNT.GENERAL_DOWNLOAD + '/not-found');
      } finally {
        store.isLoading = false
      }
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.MASTER_GROUP,
    component: MasterGroupList,
    beforeEnter: async (to, from, next) => {
      let data = localStorage.getItem('business');
      if(!data){
        next(ROUTER_PATH.MY_PAGES);
      }else{
        data = JSON.parse(data);
        if(data.role === 'master' || data.role === 'admin'){
          next();
        }else{
          next(ROUTER_PATH.MY_PAGES);
        }
      }
    },
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.MASTER_DOWNLOAD,
    component: MasterDownload,
    beforeEnter: async (to, from, next) => {
      let data = localStorage.getItem('business');
      if(!data){
        next(ROUTER_PATH.MY_PAGES);
      }else{
        data = JSON.parse(data);
        if(data.role === 'master' || data.role === 'admin'){
          next();
        }else{
          next(ROUTER_PATH.MY_PAGES);
        }
      }
    },
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
  },
  {
    path: ROUTER_PATH.BUSINESSACCOUNT.MASTER_USER,
    component: MasterUserList,
    beforeEnter: async (to, from, next) => {
      let data = localStorage.getItem('business');
      if(!data){
        next(ROUTER_PATH.MY_PAGES);
      }else{
        data = JSON.parse(data);
        if(data.role === 'master' || data.role === 'admin'){
          next();
        }else{
          next(ROUTER_PATH.MY_PAGES);
        }
      }
    },
    meta: {
      middleware: [(context) => auth(context, TYPE_USER.USER)],
    },
  },
  {
    path: ROUTER_PATH.USER_GUIDE.FAQ,
    component: UserGuideFaq,
    meta: {},
  },
  {
    path: ROUTER_PATH.USER_GUIDE.SEARCH,
    component: UserGuideFaqSearch,
    meta: {},
  },
  {
    path: ROUTER_PATH.USER_GUIDE.CATEGORY,
    component: UserGuideCategory,
    meta: {},
  },
  {
    path: ROUTER_PATH.USER_GUIDE.DETAIL,
    component: UserGuideFaqDetail,
    meta: {},
  },
];
