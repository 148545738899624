import { defineStore } from "pinia";
import {
  getDetailTourCustomerApi,
  getInfoCustomerApi,
  getDetailReservation,
  getListLocationApi,
  getListGroupAreaApi,
  getListCategorySpaceApi
} from "@/client/api";

const useCustomer = defineStore("customer", {
  state: () => ({
    infoBusiness: {},
    tourDetail: {},
    listLocation: {},
    listGroupArea: {},
    listCategory: {},
    infoCustomer: null,
    infoCheckReservation: null,
    isLoading: false,
    reservationRequest: {
      spaceId: "",
      planName: "",
      maximumCapacity: "",
      minimumCapacity: "",
      totalPrice: "",
      timeStart: "",
      timeEnd: "",
      dateSelect: "",
      detailYear: "",
      detailMonth: "",
      detailDay: "",
      nameSpace: "",
    },
    isInstantBooking: false,
    isShowModalSearch: false,
    listReservationFail: [],
    listReservationSuccess: [],
    listOption: [],
  }),
  getters: {
    getTourDetail: (state) => state.tourDetail,
    getInfoCustomer: (state) => state.infoCustomer,
    getInfoCheckReservation: (state) => state.infoCheckReservation,
    getListLocation: (state) => state.listLocation,
    getListGroupArea: (state) => state.listGroupArea,
    getListCategory: (state) => state.listCategory
  },
  actions: {
    async fetchTourDetail(id) {
      const { data } = await getDetailTourCustomerApi(id);
      this.tourDetail = data;
    },
    async fetchCustomerInfo() {
      const { data } = await getInfoCustomerApi();
      this.infoCustomer = data;
    },
    async fetchReservationInfo(id) {
      const res = await getDetailReservation(id);
      if (res.status == 200) {
        this.infoCheckReservation = res.data;
      }
    },
    async fetchListlocation() {
      const res = await getListLocationApi();
      if (res.status == 200) {
        return this.listLocation = res.data;
      }
    },
    async fetchListGroupArea() {
      const res = await getListGroupAreaApi();
      if (res.status == 200) {
        return this.listGroupArea = res.data;
      }
    },
    async fetchListCategory() {
      const res = await getListCategorySpaceApi();
      if (res.status == 200) {
        return this.listCategory = res.data;
      }
    },
  },
});
export default useCustomer;
