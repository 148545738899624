<template>
  <div>
    <!-- <v-overlay> -->
    <v-progress-circular
      :size="100"
      :width="7"
      color="info"
      indeterminate
			class="progress-circular"
    ></v-progress-circular>
    <!-- </v-overlay> -->
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.progress-circular {
	z-index: 9999999;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
