import i18n from "@/client/i18n";
import moment from "moment";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
const { t } = i18n.global;

export const convertReservationPaymentMethod = (status, payment_method, previous_status) => {
  let result = "-";
  if (status == "reservation-by-proxy-selecting-customer-payment-method") {
    return "まだ選択されていません";
  } else if (status == "reservation-free-of-charge" || previous_status == "reservation-free-of-charge") {
    return "お支払不要";
  }

  switch (payment_method) {
    case "credit-card":
      result = "カード決済";
      break;
    case "bank-transfer":
      result = "銀行振込";
      break;
    case "cash-on-site":
      result = "当日現金払い";
      break;
    case "receipt":
      result = "請求書払い";
      break;
    case "paypay":
      result = "PayPay";
      break;
    case "rakuten-pay":
      result = "楽天ペイ";
      break;
    case "amazon-pay":
      result = "AmazonPay";
      break;
    case "other":
      result = "対象外";
      break;
    default:
      result = "-";
      break;
  }
  return result;
};

export const convertReservationStatus = (status, previous_status) => {
  let result = "-";
  switch (status) {
    case "transfer-schedule":
      result = "予約確定";
      break;
    case "deposited":
      result = "予約確定";
      break;
    case "card-charged":
      result = "予約確定";
      break;
    case "receipt":
      result = "予約確定";
      break;
    case "scheduled-to-be-handed-over-on-the-day":
      result = "予約確定";
      break;
    case "reservation-free-of-charge":
      result = "予約確定";
      break;
    case "pending-approval-from-owner":
      result = "リクエスト中";
      break;
    case "requesting-cancellation":
      result = "キャンセル処理中";
      break;
    case "canceled-(penalty)":
      if (previous_status == "requesting-cancellation") {
        result = "利用者キャンセル";
        break;
      } else {
        result = "管理者キャンセル";
        break;
      }
    case "canceled-(no-penalty)":
      if (previous_status == "requesting-cancellation") {
        result = "利用者キャンセル";
        break;
      } else {
        result = "管理者キャンセル";
        break;
      }
    case "reservation-request-canceled":
      result = "リクエスト取消";
      break;
    case "cancellation-of-customer-payment-selection-(proxy-reservation)":
      result = "代理申請取消";
      break;
    case "cancel-credit-card-not-entered":
      result = "管理者キャンセル";
      break;
    case "denial-by-owner":
      result = "リクエスト否認";
      break;
    case "reservation-by-proxy-selecting-customer-payment-method":
      result = "申し込み待ち";
      break;
    case "reservation-by-proxy: Waiting for supervisor-approval":
      result = "代理申請中";
      break;
    case "denied-by-superior":
      result = "代理申請否認";
      break;
    default:
      result = "-";
      break;
  }
  return result;
};

export const convertPaymentStatus = (payment_status) => {
  let result = "";
  switch (payment_status) {
    case "waiting_for_payment":
      result = "支払待ち";
      break;
    case "payment_completed":
      result = "支払済み";
      break;
    case "no_charge":
      result = "請求なし";
      break;
    case "pending_approval":
      result = "確定前";
      break;
    case "refund_processing":
      result = "払い戻し";
      break;
    case "has_not_been_selected":
      result = "未選択";
      break;
    default:
      result = "";
      break;
  }
  return result;
};

export const isCheckValidate = (data, error) => {
  Object.keys(data).forEach((item) => {
    if (!data[item]) {
      error[item] = t(`booking_tour_space.require`);
    } else {
      error[item] = null;
    }
  });
};
export const convertDateOfBirth = (date) => {
  if (date) {
    if (String(date).length === 8) {
      const year = String(date).substr(0, 4);
      const month = String(date).substr(4, 2);
      const day = String(date).substr(6, 2);
      const newDate = new Date(`${year}-${month}-${day}`);
      const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
        locale: ja,
      });
      return newDate2;
    } else {
      // let result = "";
      // const year = String(date).substr(0, 4);
      // const month = String(date).substr(4, 2);
      // const day = String(date).substr(6, 2);
      // result = `${year}年${month}月${day}日`;
      const newDate = new Date(moment.unix(date).format("YYYY-MM-DD"));
      const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
        locale: ja,
      });
      return newDate2;
    }
  }
};
export const convertDateOfBirth3 = (date) => {
  if (date) {
    if (String(date).length === 8) {
      const year = String(date).substr(0, 4);
      const month = String(date).substr(4, 2);
      const day = String(date).substr(6, 2);
      const newDate = new Date(`${year}-${month}-${day}`);
      const newDate2 = format(newDate, "yyyy/M/dd  (E)", {
        locale: ja,
      });
      return newDate2;
    } else {
      // let result = "";
      // const year = String(date).substr(0, 4);
      // const month = String(date).substr(4, 2);
      // const day = String(date).substr(6, 2);
      // result = `${year}年${month}月${day}日`;
      const newDate = new Date(moment.unix(date).format("YYYY-MM-DD"));
      const newDate2 = format(newDate, "yyyy/M/dd  (E)", {
        locale: ja,
      });
      return newDate2;
    }
  }
};
export const convertDateOfBirth1 = (date) => {
  if (date) {
    const year = String(date).substr(0, 4);
    const month = String(date).substr(4, 2);
    const day = String(date).substr(6, 2);
    const newDate = new Date(`${year}-${month}-${day}`);
    const newDate2 = format(newDate, "yyyy年MM月dd日  (E)", {
      locale: ja,
    });
    return newDate2;
  }
};
export const convertDate = (date) => {
  if (date) {
    const newDate = new Date(moment(date).format("YYYY-MM-DD"));
    const newTime = moment(date).format("HH:mm");

    const newDate2 = format(newDate, "yyyy年M月dd日  (E)", {
      locale: ja,
    });
    return `${newDate2} ${newTime}`;
  }

  // console.log("test", test2);
  // console.log(
  //   format(test, "yyyy年MM月dd  (E)", {
  //     locale: ja,
  //   })
  // );
  // return date ? moment(date).format("YYYY年M月D日 HH時mm分") : "";
};

export const convertDateWithoutPrefix = (date, isShowTime = false) => {
  if (date) {
    const newDate = new Date(moment(date).format("YYYY-MM-DD"));
    const newTime = moment(date).format("HH:mm");

    let formattedNewDate = format(newDate, "yyyy年M月dd日", {
      locale: ja,
    });

    if (isShowTime) formattedNewDate += newTime;

    return formattedNewDate;
  }
};

export const convertLimitDate = (reservationCreatedDay, dayOfUse) => {
  if (!(moment.isMoment(reservationCreatedDay) && moment.isMoment(dayOfUse))) {
    throw new Error(
      "The parameters reservationCreatedDay and dayOfUse should be instances of moment."
    );
  }

  const deadline = reservationCreatedDay.add(7, "day");
  // The deadline of payment will be set 7 days later of the reservation record created
  //  if the day of use the space is 8 or more days later than the reservation record created.
  // Otherwise the deadline will be set the day before day of use the space.
  if (deadline.isBefore(dayOfUse)) {
    return format(deadline.toDate(), "yyyy/MM/dd (E)", { locale: ja });
  } else {
    return format(dayOfUse.subtract(2, "day").toDate(), "yyyy/MM/dd (E)", {
      locale: ja,
    });
  }
}

export const convertImgPathWithEnv = (pathImage) => {
  let result = ''
  const baseURL = process.env.VUE_APP_API_URL;
  if (pathImage && baseURL) {
    result = baseURL + pathImage;
  }
  return result
}

export const convertReservationStatusColor = (status) => {
  switch (status) {
    case "pending-approval-from-owner":
      return "color: #8a6d3b";
    case "reject-due-to-timeout":
      return "color: #a94442";
    case "reservation-request-canceled":
      return "color: #a2ab58";
    case "waiting-for-card-information-input":
      return "color: #31708f";
    case "scheduled-to-be-handed-over-on-the-day":
      return "color: #31708f";
    case "completed-cash-payment":
      return "color: #3c763d";
    case "reservation-by-proxy: Waiting for supervisor-approval":
      return "color: #3c763d";
    case "card-charged":
      return "color: #3c763d";
    case "transfer-schedule":
      return "color: #8a6d3b";
    case "reservation-by-proxy-selecting-customer-payment-method":
      return "color: #8a6d3b";
    case "reservation-free-of-charge":
      return "color: #8a6d3b";
    case "denial-by-owner":
      return "color: #a94442";
    case "requesting-cancellation":
      return "color: red";
    case "canceled-(penalty)":
      return "color: #a94442";
    case "denied-by-superior":
      return "color: #a94442";
    case "cancellation-of-customer-payment-selection-(proxy-reservation)":
      return "color: #a94442";
    case "deposited":
      return "color: #3c763d";
    case "receipt":
      return "color: #8a6d3b";
    default:
      return "color: #222222";
  }
};
// CloudFlare Image Resizing & image format chenge
// In the dvelopment env, not resizing
//
// @params pathImage String
// @params quality String (1～99)
// return String ex) "/cdn-cgi/image/format=webp,quality/image.png"
export const ImageResizing = (pathImage, quality) => {
  if (process.env.VUE_APP_MODE === "develop") {
    return pathImage;
  } else {
    return "/cdn-cgi/image/format=webp,quality=" + quality + pathImage;
  }
}
