<template>
  <v-app>
    <v-main>
      <Suspense>
      <router-view />
      </Suspense>
      <LoadingScreen v-if="isLoading" class="overlay" />
    </v-main>
  </v-app>
</template>

<script setup>
import { watch } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import useStore from "@/client/store/Customer";
import LoadingScreen from "@/admin/components/Loading/LoadingScreen.vue";

const route = useRoute();
const store = useStore();
const { isLoading } = storeToRefs(store);

function createTitleDesc(routeInstance){
    //titleを設定する処理
    if(routeInstance.meta.title){
      var setTitle = routeInstance.meta.title;
      document.title = setTitle;
    } else {
      // デフォルト
      document.title = '【公式】貸し会議室・レンタルスペース・イベント会場ならタイムシェアリング'
    }

    // メタタグのdescription設定処理
    if(routeInstance.meta.description){
      var setDesc = routeInstance.meta.description;
      document.querySelector("meta[name='description']").setAttribute('content', setDesc)
    } else {
      // デフォルト
      document.querySelector("meta[name='description']").setAttribute('content', 'カンタンネット予約！公式サイトだからできる最低価格保証で、お得に貸し会議室・レンタルスペース・イベント会場の予約ができます！無料で使い放題の設備・備品と、有料サービスで、もっと便利に！会議、セミナー、その他多数の用途別に、希望に合った時間で、会場予約。当日予約もOK！')
    }

    // canonical
    const link = document.querySelector("link[rel='canonical']")
      ? document.querySelector("link[rel='canonical']")
      : document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute(
      "href",
      'https://time-sharing.jp' + location.pathname,
    );
    document.head.appendChild(link);
}

createTitleDesc(route);

watch(route, (routeInstance) => {
  createTitleDesc(routeInstance);
});
</script>

<style lang="scss">
.v-main {
  transition: unset !important;
}
</style>
<style lang="scss" scoped>
.overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.53);
  z-index: 99999;
}
</style>
