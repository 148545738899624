import api from '../http-request'

// GET
export const getInfoCustomerBusinessApi = async (params = undefined) => api.get(`/api/business/get-info-customer`, { params });
export const getSettingInformationApi = async () => api.get(`/api/business/setting-information`);
export const DetailWithCustomerApi = async () => api.get(`/api/business/detail-with-customer`);
export const DetailBusinessApi = async (idBusiness) => api.get(`/api/business/detail/${idBusiness}`);
export const VerifyInvitationApi = async (token_invite) => api.get(`/api/business/staff/verify-invitation/${token_invite}`);


// POST
export const RegisterBusinessApi = async (data) => api.post(`/api/business/register`, data);
export const RegisterBusinessByCustomerApi = async (id, data) => api.post(`/api/business/register-by-customer/${id}`, data);
export const AcceptInvitationApi = async (token_invite, data) => api.post(`/api/business/staff/accept-invitation/${token_invite}`, data);
export const BusinessInquiryApi = async (data) => api.post(`/api/contact/form/business`, data);


//PUT



//DELETE

