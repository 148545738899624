import api from "../http-request";

export const getMemberCards = async () =>
  await api.get(`api/payments/cards`);

export const registerMemberCard = async (data) =>
  await api.post(`api/payments/cards`, data);

export const updateMemberCard = async (cardSeq, data) =>
  await api.patch(`api/payments/cards/${cardSeq}`, data);

export const deleteMemberCard = async (cardSeq) =>
  await api.delete(`api/payments/cards/${cardSeq}`);

export const getTrades = async (orderId) =>
  await api.get(`api/payments/trades/${orderId}`);

export const registerCreditTrade = async (orderId, data) =>
  await api.post(`api/payments/trades/${orderId}/credit`, data);

export const cancelCreditTrade = async (orderId, data) =>
  await api.delete(`api/payments/trades/${orderId}/credit`, data);

export const registerBankTrade = async (orderId, data) =>
  await api.post(`api/payments/trades/${orderId}/bank`, data);

export const cancelBankTrade = async (orderId, data) =>
  await api.delete(`api/payments/trades/${orderId}/bank`, data);

export const getMemberCardsForBusiness = async (groupId) =>
  await api.get(`api/business/credit-card/detail/${groupId}`);
