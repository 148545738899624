import api from "../http-request";
// import apiConstants from "../apiConstant";

// [GET METHOD]
export const getListSpaceByLocationApi = async (params) =>
  api.get(`api/rental-spaces/list/new`, { params });
export const getDetailLocationApi = async (id) =>
  api.get(`api/admin/location/detail/${id}`);
export const getLocationRecommendApi = async (id) =>
  api.get(`api/area-recommend/${id}?fe=vn`);
export const getGroupAreaRecommendApi = async (id) =>
  api.get(`api/group-area/recommend/${id}`);
export const downloadPdfInReservation = async (params) =>
  api.post(`api/rental-spaces/download-pdf`, params);
export const getListFaqLocationApi = async (id) =>
  api.get(`api/list-faq?area_id=${id}`);
export const getListGroupAreaFaqApi = async (id) =>
  api.get(`api/group-area/faq/${id}`);
export const getListGroupAreaFeaturesApi = async (id) =>
  api.get(`api/group-area/features/${id}`);
export const getListGroupAreaStatisticsApi = async (id) =>
  api.get(`api/group-area/statistics/${id}`);
export const getListFeaturesLocationApi = async (id) =>
  api.get(`api/list-features?area_id=${id}`);
export const getListAreaStatisticsApi = async (areaId) =>
  api.get(`api/area/${areaId}/statistics/list`);

export const getListSpaceRecommendApi = async (spaceId, params) =>
  api.get(`api/rental-spaces/get-ts-list-recommend/${spaceId}?version=v2`, { params });

export const getListSpaceInCampaign = async () =>
  api.get(`api/rental-spaces/list-opening-campaign?version=v2`);

export const getActivitySchedules = async (params) =>
  api.get(`api/rental-spaces/list-calendar`, { params });

export const getListCateringLocationApi = async () =>
  api.get(`api/catering-location/list-all?version=v2`);
export const getLocationCompensation = async () =>
    api.get(`api/location/compensation/list?version=v2`);

export const getListSpaceInCampaignGroup = async () =>
  api.get(`api/rental-spaces/list-area-opening-campaign?version=v2`);
