<template>
  <div class="label"><slot></slot></div>
</template>

<script>
export default {
  name: "AppLabel",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 18px;
  color: #aaa;
}
</style>
