import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from "pinia";
import router from "@/client/router";
import i18n from "@/client/i18n";
import "@/client/assets/style/base.css";
import * as VeeValidate from "vee-validate";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "@/client/assets/style/font-awesome-4.7.0/css/font-awesome.min.css"

const pinia = createPinia();
loadFonts();
const app = createApp(App);
app.use(VeeValidate, {
  inject: true,
  fieldsBagName: "$veeFields",
  errorBagName: "$veeErrors",
});
createApp(App)
  .use(vuetify)
  .use(pinia)
  .use(router)
  .use(VueToast, {
    position: "top-right",
  })
  .use(i18n)
  .mount("#app");
