<template>
  <div class="rounded-l-3xl bg-[#194165] text-[#FFF] font-semibold px-2 py-1">
    <router-link :to="loginLink">
      <span class="bg-[#FFF] text-[#194165] text-sm rounded-full px-[4px]">
        <i class="fa fa-user"></i>
      </span>
      {{ text }}
    </router-link>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ROUTER_PATH } from "@/client/const";

const { t } = useI18n();
const loginLink = ROUTER_PATH.REGISTER;
const text = t("header.my_page");
</script>