import api from '../http-request'
import apiConstants from '../apiConstant'

// [POST METHOD]
export const createCustomer = async (data) =>
  api.post(apiConstants.CUSTOMER.REGISTER, data)
export const loginCustomer = async (data) =>
  api.post(apiConstants.CUSTOMER.LOGIN, data)
export const resetPassWordUser = async (email) =>
  api.put(`${apiConstants.CUSTOMER.RECOVERY}/${email}`)
export const updateEmailCus = async (socialId, data) =>
  api.post(apiConstants.CUSTOMER.UPDATE_EMAIL + '/' + socialId, data)
export const verifyEmailExistence  = async (data) =>
  api.post("api/customer/check-exist-email", data);
export const verificationCode = async (data) =>
  api.post('api/customer/auth/verify-code', data)
export const resendVerificationCode = async (id) =>
  api.get(`api/customer/auth/verify-code/resend/${id}`)
export const changeEmailAddress = async (data) =>
  api.post('api/customer/auth/change-mail', data)
export const getInfoCustomerSocial = async (data) =>
  api.post(apiConstants.CUSTOMER.GET_INFO_SOCIAL, data)
export const requestChangeEmailAddress = async (data) =>
  api.put(apiConstants.CUSTOMER.UPDATE_NEW_EMAIL, data)
export const verifyCodeChangeNewEmail = async (data) =>
  api.put(apiConstants.CUSTOMER.VERIFY_CODE_NEW_EMAIL, data)
export const cancelRequestChangeEmailAddress = async () =>
  api.get(apiConstants.CUSTOMER.CANCEL_UPDATE_NEW_EMAIL)
export const createOrDeleteFavoriteApi = async (spaceId, data) =>
  api.post(`api/customer/favorite/${spaceId}`, data)
export const getListFavoriteApi = async () =>
  api.get(`api/customer/favorite-list`)
export const getListBrowsingHistoryApi = async () =>
  api.get(`api/customer/list-history`)