<template>
  <div class="menu">
    <div class="menu__title">{{ props.menuInfo.title }}</div>
    <div class="menu__icon">
      <div class="menu__line" style="display: flex;" v-for="buttons in separatedButtons" :key="buttons">
        <div
          class="block-icon"
          v-for="button in buttons"
          :key="button"
          @click="handlePush(button.routeName)"
        >
          <div class="icon">
            <i :class="'fa-solid '+(button.icon)"></i>
          </div>
          <div class="description">{{ button.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ROUTE_NAME } from "@/client/const";
import { defineProps, computed } from "vue";
// import { useRouter } from "vue-router";

const props = defineProps({
  menuInfo: {
    type: Object,
    require: true,
  },
});
// const router = useRouter();
const handlePush = (name) => {
  if(name !== ROUTE_NAME.CUSTOMER.FOR_PARTY) window.location.href=name
  else window.open(ROUTE_NAME.CUSTOMER.FOR_PARTY, '_blank').focus();
}
const separatedButtons = computed (() => {
  let array = [];
  for (let i = 0; i < props.menuInfo.buttons.length / 2; i++) {
    let start = i * 2
    array[i] = props.menuInfo.buttons.slice(start, start + 2);
  }

  return array;
});
</script>
<style lang="scss">
.menu {
  &__title {
    font-size: 24px;
    width: 90%;
    margin: 20px auto 10px auto;
    color: #fff;
    font-weight: bold;
  }
  &__icon {
    background: #fff;
    width: 90%;
    margin: auto;
    border-radius: 8px;
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__line {
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    .block-icon {
			width: 45%;
      height: auto;
      font-size: 13px;
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-weight: bold;
      border-radius: 8px;
      align-items: center;
      aspect-ratio: 1 / 1;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(10, 80, 201, 0.8) 100%
      );
			box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);
      color: #fff;
      * {
        display: block;
      }
      .description{
        font-size: 13px;
      }
    }
  }

  i {
    font-size: 35px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 576px){
  .menu__line{
    .block-icon{
      padding: 1rem;

      .description{
        font-size: 22px;
      }
    }
  }
  .menu i{
    font-size: 5rem;
    margin-bottom: 1rem;
  }
}
</style>
