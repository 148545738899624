<template>
  <v-card class="header-action__card dialogBestrateicon-box">
    <div class="test">
      <h1 class="dia-heading">
        公式サイト「TIME SHARING」からの予約を<span class="cheapest-price"
          >最安値</span
        >にしています。
      </h1>
      <div class="dia-body">
        <h1 class="body-title">掲載しているスペースを予約できるサイト</h1>
        <div class="body-box">
          <div>
            <div class="illustrated">
              <div class="illustrated-box">
                掲載しているスペースを<br class="d-md-none" />
                予約できるサイト
              </div>
              <ul>
                <li class="illustrated-li">
                  <dl>
                    <dt class="box-img">
                      <span class="title-box title-span">公式サイト</span>
                      <img
                        class="sp-logo"
                        :src="time_sharing_logo_sp"
                        alt="TIME SHARING タイムシェアリング｜エリア・用途・人数から探せるスペース検索予約サイト"
                        height="57"
                      />
                      <img
                        class="pc-logo"
                        :src="time_sharing_logo"
                        alt="TIME SHARING タイムシェアリング｜エリア・用途・人数から探せるスペース検索予約サイト"
                        width="244"
                        height="49"
                      />
                    </dt>
                    <dd>例：9,000円</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="title-box">比較 サイトA例</dt>
                    <dd>例：10,000円</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="title-box">比較 サイトB例</dt>
                    <dd>例：13,000円</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt class="title-box">比較 サイトC例</dt>
                    <dd>例：11,500円</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          <div class="body-box__footer">
            <p>スペースを予約できるサイトの料金設定は</p>
            <p>
              <span class="space-name">公式サイト【TIME SHARING】が</span
              ><span class="cheapest-price">最安値</span
              >となるように設定しています。
            </p>
          </div>
        </div>
      </div>
      <div class="dialogBestrateicon-footer">
        <p>最安値の対象外となる場合がございます。</p>
        <ul>
          <li>法人契約料金など、一般には公示されていない料金</li>
          <li>
            電話でしか予約を受け付けていない等、WEBサイト上で予約が出来ない料金
          </li>
          <li>
            比較検索サイト（スペースマーケット・インスタベースなど）の独自のポイントやクーポン等の付与された特典を割引と見なした料金また、一部のスペースは最安値の対象外となります。
          </li>
        </ul>
      </div>
    </div>
  </v-card>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      color="blue-grey"
      class="header-action__button header-action__dialogBestrateicon"
      fab
      @click="handleCloseModal"
    >
      <v-icon class="header-action__icon" dark color="black">
        mdi-window-close
      </v-icon>
    </v-btn>
  </v-card-actions>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch } from 'vue';
import time_sharing_logo from "@/client/assets/images/time_sharing_logo.png";
import time_sharing_logo_sp from "@/client/assets/images/timesharingSP.png";
const props = defineProps({
  isShowdialogBestrate: {
    type: Boolean,
  },
});
const isShowDialog = ref(props.isShowdialogBestrate);
const emit = defineEmits(["closeDialogBestrate"]);
watch(props, (value) => (isShowDialog.value = value.isShowdialogBestrate));
const handleCloseModal = () => {
  isShowDialog.value = false;
  emit("closeDialogBestrate");
};
</script>
<style lang="scss" scoped>
@import "./BestrateDialogCard.scss";
.sp-logo {
  display: none;
}
.pc-logo {
  display: block;
}
$XS: 576px;
$SM: 768px;
$MD: 1280px;
.header-action__dialogBestrateicon {
  top: 0;
  right: 0;
}
.dia-heading {
  font-size: 34px;
  padding: 10px 0px 10px 20px;
}
.dia-body {
  padding: 20px;
  background: linear-gradient(270deg, #157fa1 20%, #1b51a3 100%);
}
.body-title {
  font-size: 30px;
  color: white;
  margin-bottom: 20px;
}
.body-box {
  background: white;
  padding: 20px;
}
.cheapest-price {
  color: #e4007f;
  font-size: 34px;
  font-weight: bold;
}
.body-box__footer {
  margin-top: 20px;
  & p:first-child {
    font-size: 25px;
  }
  & p:last-child {
    font-size: 25px;
    & .space-name {
      font-weight: bold;
      font-size: 25px;
    }
    & .cheapest-price {
      font-size: 25px;
    }
  }
  .title-box {
    font-weight: 700;
    color: #212121;
  }
  .title-span {
    font-size: 22px;
  }
}
.dialogBestrateicon-footer {
  background: white;
  padding: 20px;
  & ul {
    margin-left: 20px;
  }
  & p,
  li {
    font-size: 14px;
    margin-bottom: 1px;
  }
}
.illustrated {
  border-radius: 15px;
  border: 3px solid #606060;
  margin: 20px 0 0 0;
  padding: 0px 10px 40px 10px;
}
.illustrated-box {
  width: fit-content;
  font-size: 30px;
  font-weight: bold;
  background: #fff;
  margin: -20px auto 40px;
  padding: 0px 8px 0;
}
.illustrated ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 10px;
  box-sizing: border-box;
  align-items: center;
}
.illustrated ul li:first-child {
  width: 45%;
}
.illustrated ul li {
  display: flex;
  flex-direction: row;
  width: 15.6666666666666%;
  margin: 0 1%;
  align-items: flex-end;
}
.illustrated ul li:first-child dl dt {
  padding: 0;
  border-bottom: 4px solid #e4007f;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}
.illustrated ul li dl {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.illustrated ul li:first-child dl dd {
  color: #e4007f;
}
.illustrated ul li dl dt {
  font-size: 22px;
  padding: 0 0 0.5em;
  list-style-type: none;
  border-bottom: 4px solid #606060;
  text-align: center;
  font-weight: bold;

  span{
    font-size: 22px;
  }
}
.illustrated ul li dl dd {
  padding: 0;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  margin: 0.5em 0 0;
}
.illustrated ul li:first-child dl dt.box-img {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .dia-heading {
    font-size: 19px;
    padding: 15px 8px;
    text-align: center;
  }
  .cheapest-price {
    color: #e4007f;
    font-size: 20px;
  }
  .body-title {
    font-size: 15px;
    color: white;
    padding: 10px 0;
    margin-bottom: 5px;
    text-align: center;
  }
  .title-box {
    padding: 0 10px;
  }
  .dia-body {
    padding: 10px;
    background: linear-gradient(270deg, #157fa1 20%, #1b51a3 100%);
  }
  .illustrated {
    padding: 0px 10px 11px 10px;
  }
  .header-action__button {
    border: 1px solid black;
    min-width: 51px;
    height: 48px;
    position: absolute;
    background: black;
    opacity: 0.5;
    border-radius: 0;
    z-index: 10000;
  }
  .mdi-window-close::before {
    content: "\F05AD";
    color: white;
    font-size: 33px;
  }
  .illustrated-box {
    font-size: 15px;
    font-weight: bold;
    background: #fff;
    margin: -10px auto 10px;
    padding: 0px 8px 0;
  }
  .d-md-none {
    display: none;
  }
  .illustrated ul {
    display: flex;

    padding: 0;
    margin: 0;
    flex-direction: column;
  }
  .illustrated ul li:first-child {
    width: 100%;
  }
  .illustrated ul li {
    margin: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .illustrated ul li {
    width: 100%;
    margin: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  .illustrated ul li:first-child dl {
    border: 2px solid #e4007f;
    margin-bottom: 1rem;
  }
  .illustrated ul li:first-child dl dt {
    border-bottom: 2px solid #e4007f;
  }
  .illustrated ul li:first-child dl dt {
    padding: 0;

    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .illustrated ul li:first-child dl dd {
    color: #e4007f;
    margin: 0px;
    padding: 0;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }
  .illustrated ul li dl {
    flex-direction: column;
    border: 2px solid #606060;
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
  }
  .illustrated ul li dl dt {
    font-size: 14px;
    padding: 0;
    border-bottom: 2px solid #606060;
    text-align: center;
    font-weight: bold;

    span{
      font-size: 14px;
    }
  }
  .illustrated ul li dl dd {
    padding: 0;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin: 0;
  }
  .body-box__footer {
    margin: 10px 5px;
    p:first-child,  p:last-child {
      font-size: 14px;
    }
    p:last-child {
      font-size: 14px;
      .space-name {
      font-size: 14px;
      }
      .cheapest-price {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: $SM) {
  .body-box {
    background: white;
    padding: 7px;
  }
  .illustrated ul li:first-child dl {
    border: 2px solid #e4007f;
    margin-bottom: 5px;
  }
  .d-md-none {
    display: block;
  }
  .illustrated-box {
    display: flex;
    text-align: center;
  }
  .illustrated ul li dl {
    flex-direction: column;
    border: 2px solid #606060;
    width: 100%;
    display: flex;
    margin-bottom: 5px;
  }
  .dialogBestrateicon-footer p {
    font-size: 12px;
  }
  .dialogBestrateicon-footer li {
    font-size: 12px;
  }
  .illustrated ul li dl dd {
    margin: 0px;
    font-size: 12px;
  }
  .illustrated ul li dl dd {
    margin: 0px;
    font-size: 12px;
  }
  .illustrated ul li:first-child dl dd {
    margin: 0px;
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .sp-logo {
    display: block;
  }
  .pc-logo {
    display: none;
  }
}
</style>