<template>
  <v-card class="px-2 py-2 h-screen">
    <div class="modal-header">
      <v-card-title>日時を選ぶ</v-card-title>
      <button @click="handleClosePopUp" type="button" class="btn-close_popup">
        <i class="fa-solid fa-xmark" style="color: #2892d7"></i>
      </button>
    </div>
    <div class="modal-content">
      <v-card class="card-text">
        <div class="c-popup-calendar-inner">
          <div class="c-popup-calendar-left">
            <FullCalendar
              v-bind:options="options"
              @click="handleDisplayBtnPrev"
            />
          </div>
          <div class="c-popup-calendar-right">
            <h3 class="title">カレンダーで利用日を選択してください。</h3>
            <div v-if="props.dataPopup?.date">
              <div class="date-client-select">
                <span class="text">選択中</span>
                <span id="value-date-popup" class="value">{{
                  dateSelect
                }}</span>
              </div>
            </div>
            <div v-else>
              <div class="date-client-select d-none">
                <span class="text">選択中</span>
                <span id="value-date-popup" class="value"></span>
              </div>
            </div>
            <div class="right-content">
              <h3 class="title">利用時間を選択してください</h3>
              <div class="group-time">
                <div class="time-start">
                  <label for="time-start">開始時間</label>
                  <div class="flex">
                    <select
                      @change="checkActiveBtnApply"
                      id="hour-start-popup"
                      class="disable mr-1"
                      name="hour-start"
                      disabled
                      v-model="dataTime.hourStart"
                    >
                      <option
                        class="option-disabled"
                        value=""
                        selected
                        disabled
                        hidden
                      >
                        ---
                      </option>
                      <option
                        :value="hStartAM"
                        v-for="hStartAM in hourStartAM"
                        :key="hStartAM"
                      >
                        午前{{ hStartAM }}
                      </option>
                      <option
                        :value="hStartPM"
                        v-for="hStartPM in hourStartPM"
                        :key="hStartPM"
                      >
                        午後{{ hStartPM }}
                      </option>
                    </select>
                    <select
                      @change="checkActiveBtnApply"
                      id="minutes-start-popup"
                      class="disable"
                      name="minutes-start"
                      disabled
                      v-model="dataTime.minutesStart"
                    >
                      <option
                        class="option-disabled"
                        value=""
                        selected
                        disabled
                        hidden
                      >
                        ---
                      </option>
                      <option
                        :value="minutes"
                        v-for="minutes in minutesStart"
                        :key="minutes"
                      >
                        {{ minutes }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="icon-right">
                  <i class="fa-solid fa-arrow-right"></i>
                </div>
                <div class="time-end">
                  <label for="time-end">終了時間</label>
                  <div class="flex">
                    <select
                      @change="checkActiveBtnApply"
                      id="hour-end-popup"
                      class="disable mr-1"
                      name="hour-end"
                      disabled
                      v-model="dataTime.hourEnd"
                    >
                      <option
                        class="option-disabled"
                        value=""
                        selected
                        disabled
                        hidden
                      >
                        ---
                      </option>
                      <option
                        :value="hEndAM"
                        v-for="hEndAM in hoursEndAM"
                        :key="hEndAM"
                      >
                        午前{{ hEndAM }}
                      </option>
                      <option
                        :value="hEndPM"
                        v-for="hEndPM in hoursEndPM"
                        :key="hEndPM"
                      >
                        午後{{ hEndPM }}
                      </option>
                    </select>
                    <select
                      @change="checkActiveBtnApply"
                      id="minutes-end-popup"
                      class="disable"
                      name="minutes-end"
                      disabled
                      v-model="dataTime.minutesEnd"
                    >
                      <option
                        class="option-disabled"
                        value=""
                        selected
                        disabled
                        hidden
                      >
                        ---
                      </option>
                      <option
                        :value="minutes"
                        v-for="minutes in minutesEnd"
                        :key="minutes"
                      >
                        {{ minutes }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <div class="modal-bottom">
      <button @click="handleApply" class="c-btn-apply" :disabled="isActive">
        適用する
      </button>
    </div>
  </v-card>
</template>

<script setup>
/* eslint-disable */
import "@/client/components/SearchComponent/ModalCalendar.scss";
import { defineEmits, reactive, ref, defineProps, onMounted } from "vue";
import "@/client/components/SearchComponent/ModalCalendar.scss";
import "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

const dataTime = ref({
  hourStart: "",
  minutesStart: "",
  hourEnd: "",
  minutesEnd: "",
});

const emit = defineEmits([
  "handleCloseModal",
  "selectDate",
  "selectTimeStart",
  "selectTimeEnd",
  "handleSelectFullDateTime",
]);
const handleClosePopUp = () => {
  emit("handleCloseModal", 123);
};

const props = defineProps({
  dataPopup: {
    type: Object,
    default: () => {},
  },
});

const dateSelect = props.dataPopup?.date.replaceAll("-", "/");

const options = reactive({
  locale: "ja",
  defaultDate: "",
  headerToolbar: {
    left: "prev",
    center: "title",
    right: "next",
  },
  plugins: [dayGridPlugin, interactionPlugin],
  interactionPlugin: "dayGridMonth",
  selectable: false,
  displayEventTime: false,
  // validRange: {
  //     end: new Date(today.getFullYear(), today.getMonth() + 2, 0)
  // },
  dateClick: function (info) {
    const date = info.dateStr;
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);
    const dayBook = year + "/" + month + "/" + day;

    if (moment(info.date).diff(moment(new Date()), "day") >= 0) {
      const valueEl = document.querySelector("#value-date-popup");
      const dateClinetSelect = document.querySelector(".date-client-select");
      dateClinetSelect.classList.remove("d-none");
      valueEl.textContent = dayBook;
      info.dayEl.style.backgroundColor = "#bce8f14d";
      const dayEls = document.querySelectorAll(".fc-day");
      for (let i = 0; i < dayEls.length; i++) {
        if (dayEls[i] !== info.dayEl) {
          dayEls[i].style.backgroundColor = "unset";
          dayEls[i].classList.remove("day-select");
        }
      }
      const hourStart = document.getElementById("hour-start-popup");
      hourStart.removeAttribute("disabled");
      hourStart.classList.remove("disable");
    } else {
      info.dayEl.style.backgroundColor = "unset";
      return false;
    }
  },
  dayCellDidMount: function (info) {
    const listDay = document.querySelectorAll(".fc-day");
    var day = moment(info.date).format("DD");
    var originElement = info.el.querySelectorAll(".fc-daygrid-day-number");
    originElement.forEach((e) => e.classList.add("d-none"));
    var targetElement = info.el.querySelectorAll(".fc-daygrid-day-top");
    targetElement.forEach((e) => (e.innerHTML = day));
    if (props.dataPopup?.date) {
      for (let i = 0; i < listDay.length; i++) {
        if (
          moment(listDay[i].getAttribute("data-date")).isSame(
            moment(props.dataPopup.date),
            "day"
          )
        ) {
          listDay[i].classList.add("day-select");
        }
      }
      const hStart = document.querySelector("#hour-start-popup");
      const mStart = document.querySelector("#minutes-start-popup");

      const hEnd = document.querySelector("#hour-end-popup");
      const mEnd = document.querySelector("#minutes-end-popup");

      const hStartOptions = hStart.options;
      const mStartOptions = mStart.options;
      const hStartTime = props.dataPopup?.timeStart
        ? props.dataPopup?.timeStart.split(":")
        : [];
      if (hStartTime.length) {
        for (let i = 1; i < hStartOptions.length; i++) {
          if (hStartOptions[i].value == hStartTime[0]) {
            hStartOptions[i].setAttribute("selected", true);
          }
        }
        for (let i = 1; i < mStartOptions.length; i++) {
          if (mStartOptions[i].value == hStartTime[1]) {
            mStartOptions[i].setAttribute("selected", true);
          }
        }
      }
        hStart.removeAttribute("disabled");
        hStart.classList.remove("disable");
        mStart.removeAttribute("disabled");
        mStart.classList.remove("disable");
    

        const hEndOptions = hEnd.options;
      const mEndOptions = mEnd.options;
      const hEndTime = props.dataPopup?.timeEnd
        ? props.dataPopup?.timeEnd.split(":")
        : [];
      if (hEndTime.length) {
        for (let i = 1; i < hEndOptions.length; i++) {
          if (hEndOptions[i].value == hEndTime[0]) {
            hEndOptions[i].setAttribute("selected", true);
          }
        }
        for (let i = 1; i < mEndOptions.length; i++) {
          if (mEndOptions[i].value == hEndTime[1]) {
            mEndOptions[i].setAttribute("selected", true);
          }
        }
      }
        hEnd.removeAttribute("disabled");
        hEnd.classList.remove("disable");
        mEnd.removeAttribute("disabled");
        mEnd.classList.remove("disable");
        checkActiveBtnApply();
    }
  },
});

const hoursEndAM = [];
const hoursEndPM = [];
const hourStartAM = [];
const hourStartPM = [];
const minutesStart = [];
const minutesEnd = [];
for (let i = 0; i <= 11; i += 1) {
  if (i < 10) {
    hourStartAM.push("0" + i);
    hoursEndAM.push("0" + i);
  } else {
    hourStartAM.push(`${i}`);
    hoursEndAM.push(`${i}`);
  }
}
for (let i = 12; i <= 24; i += 1) {
  if (i == 24) {
    hoursEndPM.push(`${i}`);
  } else {
    hourStartPM.push(`${i}`);
    hoursEndPM.push(`${i}`);
  }
}
for (let i = 0; i <= 45; i += 15) {
  let minutes = 0;
  if (i == 0) {
    minutes = "00";
  } else {
    minutes = i;
  }
  minutesStart.push(`${minutes}`);
  minutesEnd.push(`${minutes}`);
}

//check disable button apply
const isActive = ref(true);
const checkActiveBtnApply = () => {
  const hourStart = document.getElementById("hour-start-popup");
  const hourEnd = document.getElementById("hour-end-popup");
  const minutesStart = document.getElementById("minutes-start-popup");
  const minutesEnd = document.getElementById("minutes-end-popup");
  if (hourStart.value !== "") {
    minutesStart.removeAttribute("disabled");
    minutesStart.classList.remove("disable");
    if (hourStart.value > hourEnd.value && hourEnd.value) {
      dataTime.value.hourEnd = "";
      dataTime.value.minutesEnd = "";
      isActive.value = true;
      minutesEnd.setAttribute("disabled", "disabled");
      minutesEnd.classList.add("disable");
    }
    if (minutesStart.value !== "") {
      hourEnd.removeAttribute("disabled");
      hourEnd.classList.remove("disable");
      const hourEndOption = hourEnd.getElementsByTagName("option");
      let valueCheck = hourStart.value;
      if (minutesStart.value == "45") {
        valueCheck = hourStart.value + 1;
      }
      for (const option of hourEndOption) {
        if (option.value < valueCheck) {
          option.style.display = "none";
        } else {
          option.style.display = "block";
        }
        option.value === "" ? (option.style.display = "block") : "";
      }

      if (hourEnd.value !== "") {
        minutesEnd.removeAttribute("disabled");
        minutesEnd.classList.remove("disable");

        if (hourEnd.value > hourStart.value) {
          const minutesEndOption = minutesEnd.getElementsByTagName("option");
          for (const option of minutesEndOption) {
            option.style.display = "block";
            option.value === "" ? (option.style.display = "block") : "";
          }
          if (hourEnd.value == "24") {
            const minutesEndOption = minutesEnd.getElementsByTagName("option");
            for (const option of minutesEndOption) {
              if (option.value == "00") {
                option.style.display = "block";
                minutesEnd.value = "00";
              } else {
                option.style.display = "none";
              }
              option.value === "" ? (option.style.display = "block") : "";
            }
          }
        }
        if (hourEnd.value == hourStart.value) {
          if (minutesStart.value >= minutesEnd.value && minutesEnd.value) {
            dataTime.value.hourEnd = "";
            dataTime.value.minutesEnd = "";
            isActive.value = true;
            minutesEnd.setAttribute("disabled", "disabled");
            minutesEnd.classList.add("disable");
          }
          const minutesEndOption = minutesEnd.getElementsByTagName("option");
          for (const option of minutesEndOption) {
            if (option.value < minutesStart.value + 15) {
              option.style.display = "none";
            } else {
              option.style.display = "block";
            }
            option.value === "" ? (option.style.display = "block") : "";
          }
        }
      }
    }
  }
  if (
    hourStart.value !== "" &&
    hourEnd.value !== "" &&
    minutesStart.value !== "" &&
    minutesEnd.value !== ""
  ) {
    if (
      Number(hourStart.value) * 60 + Number(minutesStart.value) <
      Number(hourEnd.value) * 60 + Number(minutesEnd.value)
    ) {
      isActive.value = false;
      dataTime.value.hourStart = hourStart.value;
      dataTime.value.hourEnd = hourEnd.value;
      dataTime.value.minutesStart = minutesStart.value;
      dataTime.value.minutesEnd = minutesEnd.value;
    }
  }
};

const handleApply = () => {
  const btnRevemoValueDate = document.querySelector(
    ".search-content-calendar-btn-reset"
  );
  btnRevemoValueDate.classList.add("active");
  const valueDate = document.getElementById("value-date-popup").innerText;

  const hStart = document.getElementById("hour-start-popup").value;
  const hEnd = document.getElementById("hour-end-popup").value;
  const mStart = document.getElementById("minutes-start-popup").value;
  const mEnd = document.getElementById("minutes-end-popup").value;
  const timeStart = hStart + ":" + mStart;
  const timeEnd = hEnd + ":" + mEnd;
  const selectCalendar = document.querySelector("#select-calendar");
  const fullDateTime = valueDate + " " + timeStart + "～" + timeEnd;
  selectCalendar.value = fullDateTime;
  emit("selectDate", valueDate);
  emit("selectTimeStart", timeStart);
  emit("selectTimeEnd", timeEnd);
  emit("handleSelectFullDateTime", fullDateTime);
  handleClosePopUp();
};

const handleDisplayBtnPrev = () => {
  const activeDate = document.getElementsByClassName("fc-toolbar-title")?.[0];
  const prv = document.getElementsByClassName("fc-toolbar-chunk")?.[0];

  if (activeDate && prv) {
    const nowMonth = moment().format("YYYY年MM月");
    const activeMonth = moment(activeDate.innerText, "YYYY年MM月").format(
      "YYYY年MM月"
    );
    const prevButton = prv.getElementsByTagName("button")?.[0];
    if (nowMonth === activeMonth) {
      if (prevButton) {
        prevButton.disabled = true;
        prv.style.opacity = 0;
      }
    } else {
      if (prevButton) {
        prevButton.disabled = false;
        prv.style.opacity = 1;
      }
    }
  }
};

onMounted(() => {
  handleDisplayBtnPrev();
});
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .h-screen {
    max-height: 80dvh !important;
  }
  .c-popup-calendar .modal-content .v-card {
    overflow-y: auto;
    padding-bottom: 30px !important;
  }
}
</style>
